import React from 'react';
import { auth, db } from '../utils/firebase';

const UserDataContext = React.createContext({
  user: null,
  clients: [],
  currentClient: '',
  setClients: () => {},
  setCurrentClient: () => {}
});

export const UserDataProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [clients, setClients] = React.useState([]);
  const [currentClient, setCurrentClient] = React.useState('');

  const fetchClientes = clientsDB => {
    const promises = clientsDB.map(client =>
      db
        .collection('clientUsers')
        .doc(client)
        .get()
        .then(doc => {
          return { id: doc.id, ...doc.data() };
        })
        .catch(console.error)
    );

    return Promise.all(promises).then(x => x);
  };

  const fetchByEmail = user =>
    db
      .collection('users')
      .where('email', '==', user.email)
      .get()
      .then(docs => {
        if (docs.size === 0) {
          return;
        }
        const newDocs = [];
        docs.forEach(doc => {
          const docData = { ...doc.data(), id: doc.id };
          newDocs.push(docData);
        });
        return fetchClientes(newDocs[0].clients ?? []);
      })
      .then(clientsDB => {
        setClients(clientsDB);
      });

  const firebaseAuthEffect = () => {
    auth.onAuthStateChanged(async user => {
      if (user) {
        setUser(user);
        fetchByEmail(user);
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(firebaseAuthEffect, []);

  return (
    <UserDataContext.Provider
      value={{ user, clients, currentClient, setCurrentClient, setClients }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => React.useContext(UserDataContext);
