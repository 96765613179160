import React, { useCallback, useEffect, useRef, useState } from 'react';
import Abstracto3D from '../../assets/svgs/Abstracto3D';
import Arrow from '../../assets/svgs/Arrow';
import { FlexWrap } from '../Home/HomeComponents';
import {
  ButtonText,
  Historia,
  LinkButton,
  TextoHistoria
} from '../Nosotros/NosotrosComponents';
import { colors } from '../../utils/exports';
import SearchInput from '../Abstracto+/SearchInput/SearchInput';
import ScrollAnimation from 'react-animate-on-scroll';
import { returnImg } from '../../utils/returnImg';
import facebook from '../../assets/svgs/fb-icon.svg';
import twitter from '../../assets/svgs/twitter-logo.svg';
import whatsapp from '../../assets/svgs/whatsapp-icon.svg';
import './Publicaciones.scss';
import { db } from '../../utils/firebase';
import { useParams } from 'react-router-dom';
import { dateFormatter, getImgPathFirebase } from '../utils';
import Skeleton from 'react-loading-skeleton';
import { INPUT_STATES, usePlus } from '../../Contextos/PlusContext';

function Publicaciones({ setHeaderLogoColor }) {
  const { urlArticulo } = useParams();
  const shownFooter = useRef(false);
  const historiaRef = useRef();
  const [logoShow, setLogoShow] = useState('hidden');
  const [section, setSection] = useState('abstracto+');
  const [articulo, setArticulo] = useState(null);
  const { setTipoContenido } = usePlus();

  const handleScroll = useCallback(() => {
    if (!shownFooter.current) {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 1
      ) {
        setTimeout(
          () => (document.getElementById('footer').style.display = 'block'),
          1
        );
        shownFooter.current = true;
      }
    }
    const bounding = historiaRef.current.getBoundingClientRect();
    let bottomThreshold;
    let topThreshold;

    //1 Define bottomThreshold y topThreshold de cada size
    // Laptop
    if (window.innerWidth > 1151 && window.innerWidth < 1439) {
      bottomThreshold = window.innerHeight - 290;
      topThreshold = 180;
    }
    // Tablet
    else if (window.innerWidth > 767 && window.innerWidth < 1151) {
      bottomThreshold = window.innerHeight - 404;
      topThreshold = 150;
    }
    // Desktop
    else if (window.innerWidth > 1439) {
      bottomThreshold = window.innerHeight - 300;
      topThreshold = 180;
    }
    // Mobile
    else if (window.innerWidth < 768) {
      topThreshold = 60;
      bottomThreshold = window.innerHeight - 300;
    }
    //2 Círculos barra lateral izquierda
    if (bounding.top <= 300 && bounding.bottom <= bottomThreshold) {
      if (section === 'abstracto+') {
        setSection('contacto');
      }
    } else {
      if (section === 'contacto') {
        setSection('abstracto+');
      }
    }
    //3 Logo abstracto 3D
    if (bounding.top < topThreshold && bounding.bottom > 250) {
      setLogoShow('visible');
    } else {
      setLogoShow('hidden');
    }
  }, [section]);

  useEffect(() => {
    setHeaderLogoColor(colors.negro);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, setHeaderLogoColor]);

  useEffect(() => {
    db.collection('posts')
      .where('url', '==', urlArticulo)
      .onSnapshot(querySnapshot => {
        setArticulo(querySnapshot.docs[0].data());
      });
  }, [urlArticulo]);

  useEffect(() => {
    setTipoContenido(INPUT_STATES.ARTICULOS);
  }, [setTipoContenido]);

  return (
    <>
      <main id="abstracto-plus-articulo-main">
        <SearchInput urlArticulo={urlArticulo} />
        <div className="contiene-articulo">
          <div className="estructura-articulo">
            <div className="titulo">
              {articulo?.titulo ? articulo?.titulo : <Skeleton count={2} />}
            </div>
            <span className="autor">
              {articulo?.autor ? (
                `Escrito por: ${articulo?.autor}`
              ) : (
                <Skeleton />
              )}
            </span>
            <span className="fecha">
              {articulo?.fecha ? (
                `Fecha:  ${dateFormatter(articulo?.fecha.toDate())}`
              ) : (
                <Skeleton />
              )}
            </span>
            {articulo?.imagen1 ? (
              <div
                className="imagen"
                style={{
                  backgroundImage: `url(${getImgPathFirebase(
                    articulo?.imagen1
                  )})`
                }}
              ></div>
            ) : (
              <Skeleton height={500} width={300} />
            )}
            {articulo?.contenido ? (
              <div
                className="cuerpo"
                dangerouslySetInnerHTML={{ __html: articulo?.contenido }}
              ></div>
            ) : (
              <Skeleton count={30} />
            )}
          </div>
          <div className="estructura-compartir">
            <div className="linea-cover"></div>
            <span className="compartir-articulo">Compartir artículo</span>
            <div className="logos-compartir">
              <span
                onClick={function lol() {
                  window.FB.ui({
                    method: 'share',
                    href: window.location.href
                  });
                }}
              >
                <img
                  src={facebook}
                  alt="Logo de facebook"
                  className="facebook"
                ></img>
              </span>
              <a
                href={`https://wa.me/?text=${window.location.href}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={whatsapp}
                  alt="Logo de whatsapp"
                  className="whatsapp"
                ></img>
              </a>
              <a
                href={`https://twitter.com/intent/tweet?text=${window.location.href}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={twitter}
                  alt="Logo de instagram"
                  className="twitter"
                ></img>
              </a>
            </div>
          </div>
        </div>
      </main>
      <Historia ref={historiaRef}>
        <ScrollAnimation
          animateIn="animate__fadeInUp"
          duration={1.5}
          animateOnce={true}
        >
          <TextoHistoria id="texto-historia-marketing">
            En abstracto estamos listos para nuevos retos,siempre aprendiento y
            siempre soñando.
          </TextoHistoria>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="animate__fadeIn"
          duration={1}
          animateOnce={true}
        >
          <ScrollAnimation
            animateIn="animate__headShake"
            duration={2.0}
            animateOnce={true}
            className="scroll"
          >
            <LinkButton>
              <Arrow color={colors.naranja} />
              <ButtonText>HAGAMOS HISTORIA JUNTOS</ButtonText>
            </LinkButton>
          </ScrollAnimation>
        </ScrollAnimation>
        <FlexWrap id="abstracto-marketing">
          <Abstracto3D show={logoShow} />
        </FlexWrap>
        <FlexWrap id="senor-marketing">
          <img
            className="senor"
            src={returnImg('Señor')}
            alt="Señor caminando"
          />
        </FlexWrap>
      </Historia>
    </>
  );
}

export default Publicaciones;
