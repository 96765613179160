import { useEffect } from 'react';

import './AvisoPrivacidad.scss';

export const AvisoPrivacidad = ({ setHeaderLogoColor }) => {
  useEffect(() => {
    setHeaderLogoColor('black');
  }, [setHeaderLogoColor]);
  return (
    <div className="aviso-privacidad">
      <h1>AVISO DE PRIVACIDAD.</h1>
      <p>
        El presente documento constituye el Aviso de Privacidad para efectos de
        lo dispuesto en la Ley Federal de Protección de Datos Personales en
        Posesión de los Particulares y las disposiciones que de ella emanan o se
        relacionan con la misma. Este Aviso de Privacidad aplica a la
        información personal recopilada sobre el Titular de nombre ABSTRACTO, en
        su carácter de Responsable.
      </p>
      <h2>¿Qué información recopilamos?</h2>
      <p>
        El Responsable de la agencia recolecta información que puede
        identificarle de manera razonable, y que estos constituyen en:
      </p>
      <p>
        <b>DATOS PERSONALES</b>, tales como; Datos de Identificación: Nombre y
        Apellidos; Fecha de nacimiento; Datos de Geolocalización: Domicilio;
        Datos de Contacto: Dirección de Correo Electrónico; Número de Teléfono
        fijo y/o celular; Datos Fiscales y Bancarios: Registro Federal de
        Contribuyentes (RFC); Número de tarjetas bancarias y/o cuentas.
      </p>
      <p>
        DATOS PERSONALES SENSIBLES, tales como Ideología (Especialmente
        Filiación Sindical, Partido Político, etc). Origen Racial; Origen
        Étnico,: Creencias Religiosas y Hábitos Personales
      </p>
      <h2>¿Cómo recopilamos la información?</h2>
      <p>
        <b>ABSTRACTO</b> podrá recabar sus datos de manera directa, por medio de
        cualquiera de nuestras oficinas y/o sucursales, por cualquier solicitud
        de cotización para proyecto, campaña o cualquier actividad relacionada
        por la agencia y esta información puede ser recabada por medios físicos
        y/o electrónicos, llamadas telefónicas, video llamadas, correo, página
        web o aplicaciones móviles (apps).
      </p>
      <p>
        En caso de que los datos personales se refieran a personas físicas
        distintas al contratante, éste garantiza haber recabado y contar con el
        consentimiento previo de los mismos para la comunicación de sus datos y
        haberles informado con carácter previo a la transmisión de sus datos, de
        las finalidades del tratamiento, previstos en el presente Aviso de
        Privacidad. Los datos personales sensibles y financieros que se
        proporcionen, serán tratados y resguardados con un alto estándar de
        seguridad y estricta confidencialidad.
      </p>
      <h2>¿Cómo usamos o compartimos la información que recopilamos?</h2>
      <p>
        Algunos ejemplos de las finalidades de la información que recopilamos
        son:
      </p>
      <ul>
        <li>
          Utilizamos la información que recopilamos para brindarle y ofertarle
          los servicios que solicita, para informarle acerca de otros servicios
          ofrecidos por <b>ABSTRACTO</b> y para administrar nuestros sitios y
          servicios.
        </li>
        <li>
          También para realizar estudios sobre datos demográficos, intereses y
          comportamiento; estudios de mercado y de consumo a efecto de adquirir
          y ofrecer productos y servicios personalizados, así como publicidad y
          contenidos más adecuados a sus necesidades.
        </li>
        <li>
          Para formalizar el proceso contractual con clientes, consumidores,
          proveedores y otros terceros así como gestionar los servicios y
          relaciones comerciales con proveedores y otros terceros con los que
          tratamos.
        </li>
      </ul>
    </div>
  );
};
