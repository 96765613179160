import React from 'react';

const Logo = ({ color, toggle }) => {
    return (
        <svg
            id="logo"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 154 31"
            className="logo"
            onClick={toggle}
        >
            <title></title>

            <g className="link" fill={color} >
                <path d="M9.6773 10.6559C6.54809 10.6559 3.45822 11.584 1.27672 13.765L3.86949 16.3071C5.18555 15.0974 7.08095 14.2886 9.14086 14.2886C11.7766 14.2886 13.6684 15.5379 13.6684 17.9212V18.4448H12.4346C7.49222 18.4448 0 18.8889 0 25.1864C0 29.0212 3.54048 31 7.20612 31C9.84181 31 12.1485 30.0323 13.5468 27.8513H13.6684V30.5162H18.1136V19.3728C18.1172 16.6285 18.1172 10.6559 9.6773 10.6559ZM13.3823 22.926C13.3823 25.6306 11.7766 27.3674 8.40058 27.3674C6.79485 27.3674 4.94236 26.6019 4.94236 24.8253C4.94236 22.0413 9.63796 21.8391 12.3523 21.8391H13.3823V22.926Z" />
                <path d="M31.6818 10.6559C27.9768 10.6559 25.9992 12.3494 24.9692 13.6024H24.8441V0H19.9053V30.5125H24.5973V27.6057H24.6796C26.1208 29.9456 28.7135 30.9964 31.4744 30.9964C37.5683 30.9964 41.234 26.5549 41.234 20.8243C41.234 15.0938 37.3216 10.6559 31.6818 10.6559ZM30.4874 26.638C26.8646 26.638 24.6831 23.8539 24.6831 20.8243C24.6831 17.7948 26.8646 15.0107 30.4874 15.0107C34.1101 15.0107 36.2916 17.7948 36.2916 20.8243C36.2916 23.8539 34.1101 26.638 30.4874 26.638Z" />
                <path d="M90.5222 10.6559C87.5718 10.6559 84.6608 11.4828 82.5115 13.4002V10.9375C81.9357 10.775 81.3599 10.6559 80.7412 10.6559C78.1485 10.6559 75.9634 11.9883 74.8941 14.209H74.8118V11.1433H70.2199H69.8695H64.0116V5.52832H59.0692V11.1397H54.9923V12.4866C53.1613 10.97 50.8761 10.6523 48.8769 10.6523C44.8823 10.6523 40.9305 12.5913 40.9305 16.91C40.9305 24.298 51.5949 21.1493 51.5949 25.143C51.5949 26.6776 49.7424 27.3637 48.3012 27.3637C46.2019 27.3637 44.8823 26.3563 43.6091 24.9841L40.3154 28.0101C42.3324 30.2308 44.9681 30.9963 48.0151 30.9963C52.0491 30.9963 56.5372 29.3822 56.5372 24.7819C56.5372 17.235 45.8729 20.0588 45.8729 16.5887C45.8729 15.1371 47.4393 14.5304 48.7554 14.5304C50.4433 14.5304 51.6771 15.2165 52.6248 16.5092L54.9923 15.2057V15.2598H59.0692V24.1391C59.0692 28.6998 60.303 31 65.5351 31C66.6008 31 68.7394 30.5269 69.8695 30.0719V30.5161H74.8118V20.1418C74.8118 19.091 75.4699 15.2598 80.1225 15.2598C80.9057 15.2598 81.6889 15.4223 82.5115 15.6245V14.1477L84.718 16.3106C86.0341 15.1009 87.9295 14.2921 89.9894 14.2921C92.6251 14.2921 94.5169 15.5415 94.5169 17.9247V18.4483H93.2831C88.3407 18.4483 80.8485 18.8924 80.8485 25.1899C80.8485 29.0248 84.389 31.0036 88.0546 31.0036C90.6903 31.0036 92.997 30.0358 94.3953 27.8548H94.5169V30.5197H98.9622V19.3727C98.9622 16.6284 98.9622 10.6559 90.5222 10.6559ZM66.7724 26.8799C64.5909 26.8799 64.0152 25.6702 64.0152 23.7311V15.2562H69.873V26.0385C69.097 26.4465 67.5378 26.8799 66.7724 26.8799ZM94.2308 22.9259C94.2308 25.6305 92.6251 27.3673 89.2491 27.3673C87.6434 27.3673 85.7909 26.6018 85.7909 24.8252C85.7909 22.0412 90.4865 21.839 93.2008 21.839H94.2308V22.9259Z" />
                <path d="M124.951 23.7347V15.2562H130.386V11.1397H124.951V5.52832H120.008V11.1397H115.931V12.2591C114.243 11.1506 112.23 10.6559 110.356 10.6559C104.387 10.6559 99.6094 14.7326 99.6094 20.8279C99.6094 26.9232 104.384 31 110.356 31C112.087 31 115.463 30.2742 117.48 28.2954L114.186 24.8252C113.281 25.9952 111.879 26.6415 110.356 26.6415C106.733 26.6415 104.552 23.8575 104.552 20.8279C104.552 17.7983 106.733 15.0143 110.356 15.0143C111.672 15.0143 112.949 15.7798 114.104 16.9534L115.931 15.1623V15.2598H120.008V24.1392C120.008 28.6998 121.242 31 126.474 31C127.626 31 129.357 30.8375 130.386 30.3536V26.2768C129.768 26.721 128.573 26.8835 127.711 26.8835C125.53 26.8799 124.951 25.6702 124.951 23.7347Z" />
                <path d="M150.066 23.5759C150.137 23.5759 150.209 23.5831 150.277 23.5868C150.506 22.7237 150.631 21.8029 150.631 20.828C150.631 14.7327 145.857 10.6559 139.884 10.6559C133.916 10.6559 129.138 14.7327 129.138 20.828C129.138 26.9233 133.912 31 139.884 31C142.413 31 144.723 30.267 146.554 28.9598C146.289 28.4724 146.136 27.9235 146.136 27.3349C146.132 25.2622 147.902 23.5759 150.066 23.5759ZM139.884 26.638C136.262 26.638 134.08 23.854 134.08 20.8244C134.08 17.7948 136.262 15.0107 139.884 15.0107C143.507 15.0107 145.689 17.7948 145.689 20.8244C145.689 23.854 143.507 26.638 139.884 26.638Z" />
                <path d="M150.277 23.5867C149.68 25.8363 148.35 27.6743 146.551 28.9598C147.216 30.1839 148.586 31 150.066 31C152.23 31 154 29.3136 154 27.241C154 25.2369 152.301 23.6878 150.277 23.5867Z" />
            </g>
        </svg>
    );
}

export default Logo;