import React, { useCallback, useEffect, useRef, useState } from 'react';
import { colors } from '../../utils/exports';
import 'animate.css/animate.min.css';

import {
  Container,
  FixedLeft,
  FixedRight,
  FlexWrap,
  LeftSideFlexWrap,
  RightSideFlexWrap,
  Section,
  SectionLeft,
  VerticalWrapper
} from '../Home/HomeComponents';
import SmallA from '../../assets/svgs/SmallA';
import { Link } from 'react-router-dom';
import IGIcon from '../../assets/svgs/IGIcon';
import FBIcon from '../../assets/svgs/FBIcon';
import { returnImg } from '../../utils/returnImg';
import {
  ButtonText,
  Historia,
  LinkButton,
  TextoCallToAction,
  TextoHistoria
} from '../Nosotros/NosotrosComponents';
import ScrollAnimation from 'react-animate-on-scroll';
import Arrow from '../../assets/svgs/Arrow';
import Abstracto3D from '../../assets/svgs/Abstracto3D';
import abstractoPlusLogo from '../../assets/images/abstractoPlus/abstracto-plus-logo.svg';
import flechaPlus from '../../assets/svgs/flecha-plus.svg';

import './AbstractoPlus.scss';
import { Articulos } from './Articulos/Articulos';
import SearchInput from '../Abstracto+/SearchInput/SearchInput';
import { INPUT_STATES, usePlus } from '../../Contextos/PlusContext';
import { useResize } from '../../customHooks/useResize';

function AbstractoPlus({ setHeaderLogoColor }) {
  const shownFooter = useRef(false);
  const historiaRef = useRef();
  const [logoShow, setLogoShow] = useState('hidden');
  const [sideColor, setSideColor] = useState(colors.blanco);
  const [redesColor, setRedesColor] = useState(colors.blanco);
  const [aColor, setAColor] = useState(colors.blanco);
  const [section, setSection] = useState('abstracto+');
  const { inputState, generalData } = usePlus();
  const viewport = useResize();

  const handleScroll = useCallback(() => {
    if (!shownFooter.current) {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 1
      ) {
        setTimeout(
          () => (document.getElementById('footer').style.display = 'block'),
          1
        );
        shownFooter.current = true;
      }
    }
    const bounding = historiaRef.current.getBoundingClientRect();
    let bottomThreshold;
    let topThreshold;

    //1 Define bottomThreshold y topThreshold de cada size
    // Laptop
    if (window.innerWidth > 1151 && window.innerWidth < 1439) {
      bottomThreshold = window.innerHeight - 290;
      topThreshold = 180;
    }
    // Tablet
    else if (window.innerWidth > 767 && window.innerWidth < 1151) {
      bottomThreshold = window.innerHeight - 404;
      topThreshold = 150;
    }
    // Desktop
    else if (window.innerWidth > 1439) {
      bottomThreshold = window.innerHeight - 300;
      topThreshold = 180;
    }
    // Mobile
    else if (window.innerWidth < 768) {
      topThreshold = 60;
      bottomThreshold = window.innerHeight - 300;
    }
    //2 Círculos barra lateral izquierda
    if (bounding.top <= 300 && bounding.bottom <= bottomThreshold) {
      if (section === 'abstracto+') {
        setSection('contacto');
      }
    } else {
      if (section === 'contacto') {
        setSection('abstracto+');
      }
    }
    //3 Logo abstracto 3D
    if (bounding.top < topThreshold && bounding.bottom > 250) {
      setLogoShow('visible');
    } else {
      setLogoShow('hidden');
    }
    //4 Logos FB e Insta
    if (bounding.top < 750 && bounding.top > -50) {
      setRedesColor(colors.negro);
    } else {
      setRedesColor(colors.blanco);
    }
    //5 one day? or day one?
    if (bounding.top < 600 && bounding.top > -200) {
      setSideColor(colors.negro);
    } else {
      setSideColor(colors.blanco);
    }
    //6 Color Header
    if (bounding.top < 100) {
      if (bounding.top <= -750 && bounding.bottom <= bottomThreshold) {
        setAColor(colors.blanco);
        setHeaderLogoColor(colors.blanco);
      } else {
        setAColor(colors.blanco);
        setHeaderLogoColor(colors.blanco);
      }
    } else {
      if (
        window.document.querySelector('.fondo-padre').getBoundingClientRect()
          .top === 0
      ) {
        setHeaderLogoColor(colors.blanco);
      } else {
        setHeaderLogoColor(colors.blanco);
        setAColor(colors.blanco);
      }
    }
  }, [section, setHeaderLogoColor]);

  useEffect(() => {
    setHeaderLogoColor(colors.blanco);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, setHeaderLogoColor]);

  return (
    <>
      <Container>
        {viewport.isTablet ||
          (viewport.isMobile && (
            <Link to="/">
              <SmallA color={aColor} />
            </Link>
          ))}
        <FixedLeft>
          <LeftSideFlexWrap id="social-networks">
            <SectionLeft color={sideColor}>one day? or day one?</SectionLeft>
            {section === 'marketing' ? (
              <img
                className="icono"
                src={returnImg('IconAbstractoAlt')}
                alt="Icono Abstracto"
              />
            ) : (
              <img className="icono" src={returnImg('IconAbstracto')} alt="" />
            )}
            <a
              href="https://www.facebook.com/Abstracto-109168160795762?_rdc=1&_rdr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FBIcon color={redesColor} />
            </a>
            <a
              href="https://www.instagram.com/abstracto.mexico/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IGIcon color={redesColor} />
            </a>
          </LeftSideFlexWrap>
        </FixedLeft>
        <FixedRight>
          <RightSideFlexWrap>
            <Section color={sideColor}>{section}</Section>
          </RightSideFlexWrap>
        </FixedRight>
        <main id="abstracto-plus-main">
          <div className="fondo-padre">
            <h1>{generalData?.titulo}</h1>
            <span>{generalData?.texto1}</span>
            <img
              src={abstractoPlusLogo}
              alt="Logo abstracto 3D"
              className="abstract-plus-3D"
            ></img>
            <div
              style={{
                width: '100%',
                position: 'relative'
              }}
            >
              <div className="flecha-bounce">
                <VerticalWrapper style={{ paddingTop: 0, margin: 'auto' }}>
                  <div className="flecha-seleccionar-plus">
                    <span>selecciona lo que quieras ver</span>
                    <img src={flechaPlus} alt="Flecha Seleccionar"></img>
                  </div>
                </VerticalWrapper>
              </div>
            </div>
          </div>
          <SearchInput />
          {(inputState === INPUT_STATES.VIDEOS ||
            inputState === INPUT_STATES.ARTICULOS) && <Articulos />}
        </main>
        <Historia ref={historiaRef}>
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1.5}
            animateOnce={true}
          >
            <TextoHistoria id="texto-historia-marketing">
              {generalData?.cierre1}
            </TextoHistoria>
            <TextoCallToAction>{generalData?.cierre2}</TextoCallToAction>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__fadeIn"
            duration={1}
            animateOnce={true}
          >
            <ScrollAnimation
              animateIn="animate__headShake"
              duration={2.0}
              animateOnce={true}
              className="scroll"
            >
              <LinkButton>
                <Arrow color={colors.naranja} />
                <ButtonText>{generalData?.botoncierre}</ButtonText>
              </LinkButton>
            </ScrollAnimation>
          </ScrollAnimation>
          <FlexWrap id="abstracto-marketing">
            <Abstracto3D show={logoShow} />
          </FlexWrap>
          <FlexWrap id="senor-marketing">
            <img
              className="senor"
              src={returnImg('Señor')}
              alt="Señor caminando"
            />
          </FlexWrap>
        </Historia>
      </Container>
    </>
  );
}

export default AbstractoPlus;
