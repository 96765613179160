import React, { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";

import './Menu.scss';

const sidebar = {
  open: (height = 1000) => ({
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

const MenuH = ({ color }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  const toggle = () => {
    if (isOpen) {
      toggleOpen();
      document.getElementsByClassName('menu-list-hori')[0].classList.add('hidden');
      document.getElementById('cotiHeader').style.zIndex = 2000;
    } else {
      toggleOpen();
      document.getElementsByClassName('menu-list-hori')[0].classList.remove('hidden');
      document.getElementById('cotiHeader').style.zIndex = 2002;
    }
  }


  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      className="nav-hori"
    >
      <motion.div className="background" variants={sidebar} />
      <Navigation toggle={() => toggle()} />
      <MenuToggle toggle={() => toggle()} color={color} />
    </motion.nav>
  );
};

export default MenuH;
