import React from 'react';

const Arrow = ({ color }) => {
    return (
        <svg
            id="arrow"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 24 24"
            className="arrow"
        >
            <title></title>

            <g className="link" fill="none" >
                <path d="M4 12L20 12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" stroke={color} />
                <path d="M14 6L20 12L14 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" stroke={color} />
            </g>
        </svg>
    );
}

export default Arrow;