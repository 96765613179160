import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import './Articulos.scss';
import { getImgPathFirebase, isMobile } from '../../utils';
import { INPUT_STATES, usePlus } from '../../../Contextos/PlusContext';
import { db } from '../../../utils/firebase';
import { Link } from 'react-router-dom';

const frs = ['tres', 'dos', 'uno', 'cuatro', 'dosuno', 'dosdos'];

function Articulos() {
  const { categoriaActiva, inputValue } = usePlus();
  const isFilteredArticulos = inputValue;

  return (
    <>
      <div id="articulos-abstracto-plus">
        {!categoriaActiva && !isFilteredArticulos && (
          <>
            <h1>Selecciona la categoría</h1>
            <div className="grid">
              <CategoriasGrid />
            </div>
          </>
        )}
        {(categoriaActiva || isFilteredArticulos) && <Categorias />}
      </div>
    </>
  );
}

function CategoriasGrid() {
  const { ARTICULOS } = INPUT_STATES;
  const {
    setCategoriaActiva,
    tipoContenido,
    categoriasVideos,
    categoriasBlog
  } = usePlus();
  const tipo = tipoContenido === ARTICULOS ? categoriasBlog : categoriasVideos;
  if (!tipo) {
    return null;
  }
  return (
    <>
      {Object.keys(tipo)
        ?.sort(
          (key1, key2) =>
            Number(key1.match(/(\d+)/)[0]) - Number(key2.match(/(\d+)/)[0])
        )
        .map((categoria, i) => {
          const categoriasData = tipo[categoria];
          return (
            <div
              key={i}
              className={`card ${frs[i % frs.length]} ${frs[i % frs.length]}`}
              style={{ backgroundColor: `${categoriasData?.color}` }}
              onClick={() => {
                setCategoriaActiva(categoria);
              }}
            >
              <h1>{categoriasData?.nombre}</h1>
            </div>
          );
        })}
    </>
  );
}

function Categorias() {
  const { ARTICULOS } = INPUT_STATES;
  const {
    categoriaActiva,
    setCategoriaActiva,
    tipoContenido,
    categoriasVideos,
    categoriasBlog,
    inputValue,
    setInputValue
  } = usePlus();
  const tipo = tipoContenido === ARTICULOS ? categoriasBlog : categoriasVideos;
  if (!tipo) {
    return null;
  }
  if (isMobile()) {
    return (
      <div id="categorias-abstracto-plus">
        <h1>CATEGORÍAS</h1>
        <div className="categorias-dropdown">
          <select
            type="button"
            value={categoriaActiva}
            onChange={e => {
              setCategoriaActiva(e.target.value);
            }}
          >
            {Object.keys(tipo)
              ?.sort(
                (key1, key2) =>
                  Number(key1.match(/(\d+)/)[0]) -
                  Number(key2.match(/(\d+)/)[0])
              )
              .map((categoria, i) => {
                const categoriasDatas = tipo[categoria];

                return (
                  <option value={categoria} key={i}>
                    {categoriasDatas?.nombre}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="banda-categoria">
          <span>{tipo?.[categoriaActiva]?.nombre}</span>
        </div>
        <div className="contenedor-cards">
          <CardAbstracto />
        </div>
      </div>
    );
  } else {
    return (
      <div id="categorias-articulo">
        <div className="categorias-izquierdo">
          <span>CATEGORÍAS</span>
          <hr />
          <ul>
            {Object.keys(tipo)
              ?.sort(
                (key1, key2) =>
                  Number(key1.match(/(\d+)/)[0]) -
                  Number(key2.match(/(\d+)/)[0])
              )
              .map((categoria, i) => {
                const categoriasDatas = tipo[categoria];
                return (
                  <li
                    key={i}
                    className={classnames({
                      active: categoria === categoriaActiva && !inputValue
                    })}
                    onClick={() => {
                      setCategoriaActiva(categoria);
                      setInputValue('');
                    }}
                  >
                    {categoriasDatas?.nombre}
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="categorias-derecho">
          <span className="banda-derecho">
            {inputValue
              ? `Resultados para "${inputValue}"`
              : tipo?.[categoriaActiva]?.nombre}
          </span>
          <div className="contenedor-cards-articulo">
            <CardAbstracto />
          </div>
        </div>
      </div>
    );
  }
}

function CardAbstracto() {
  const {
    categoriaActiva,
    filteredArticulos,
    filteredVideos,
    tipoContenido,
    inputValue
  } = usePlus();
  const [articulosArray, setArticulosArray] = useState([]);
  const [videosArray, setVideosArray] = useState([]);
  const { ARTICULOS, VIDEOS } = INPUT_STATES;

  useEffect(() => {
    if (categoriaActiva && tipoContenido === ARTICULOS) {
      db.collection('posts')
        .where('categoria', '==', categoriaActiva)
        .onSnapshot(querySnapshot => {
          let articulosArray = [];
          querySnapshot.forEach(doc => {
            articulosArray.push(doc.data());
          });
          setArticulosArray(articulosArray);
        });
    } else if (categoriaActiva && tipoContenido === VIDEOS) {
      db.collection('videos')
        .where('categoria', '==', categoriaActiva)
        .onSnapshot(querySnapshot => {
          let videosArray = [];
          querySnapshot.forEach(doc => {
            videosArray.push(doc.data());
          });
          setVideosArray(videosArray);
        });
    }
  }, [categoriaActiva, tipoContenido, ARTICULOS, VIDEOS]);

  if (tipoContenido === ARTICULOS) {
    const articulosToShow = inputValue ? filteredArticulos : articulosArray;

    return (
      <>
        {articulosToShow?.map((articulo, i) => {
          return (
            <div id="card-abstracto" key={i}>
              <span className="titulo-card">{articulo?.titulo}</span>
              <img
                src={getImgPathFirebase(articulo?.imagen2)}
                alt="Imágen del tema"
              />
              <Link
                className="leer-articulo anchor"
                to={`/abstractoplus/articulos/${articulo?.url}`}
              >
                🡪 LEER ARTÍCULO
              </Link>
            </div>
          );
        })}
      </>
    );
  } else if (tipoContenido === VIDEOS) {
    const videosToShow = inputValue ? filteredVideos : videosArray;
    return videosToShow.map(video => {
      const url = new URL(video.url);
      return (
        <a
          href={`https://www.youtube.com/watch?v=${url.searchParams.get('v')}`}
          target="_blank"
          rel="noopener noreferrer"
          key={video}
        >
          <iframe
            title={video}
            key={video}
            width="693"
            height="390"
            src={`https://www.youtube.com/embed/${url.searchParams.get('v')}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            samesite="None"
          ></iframe>
          <span>{video.titulo}</span>
        </a>
      );
    });
  }
}

export { Articulos, Categorias };
