import React, { Suspense, useState } from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import { ToastProvider } from 'react-toast-notifications';
import { ToastContainer } from 'react-toastify';

import './Abstracto.scss';
import 'react-toastify/dist/ReactToastify.css';

import MarketingDigital from './components/MarketingDigital/MarketingDigital';
import AbstractoPlus from './components/Abstracto+/AbstractoPlus';
import CotiHeader from './components/Cotizaciones/Header/CotiHeader';
import Publicaciones from './components/Publicaciones/Publicaciones';
import { PlusContext } from './Contextos/PlusContext';
import { HeaderContextProvider } from './components/Header/HeaderContext';
import { AvisoPrivacidad } from './components/AvisoPrivacidad/AvisoPrivacidad';
import ErrorPage from './components/ErrorPage/ErrorPage';
import {UserDataProvider} from './Contextos/UserDataContext'

const Home = React.lazy(() => import('./components/Home/Home'));
const Header = React.lazy(() => import('./components/Header/Header'));
const Footer = React.lazy(() => import('./components/Footer/Footer'));
const Contacto = React.lazy(() => import('./components/Contacto/Contacto'));
const Nosotros = React.lazy(() => import('./components/Nosotros/Nosotros'));
const Cotizaciones = React.lazy(() =>
  import('./components/Cotizaciones/Cotizaciones')
);
const Login = React.lazy(() => import('./components/Admin/Login/Login'));
const Intro = React.lazy(() => import('./components/Admin/Intro/Intro'));

const IntroClientes = React.lazy(()=> import('./components/Clientes/router'))



function App() {
  const [headerLogoColor, setHeaderLogoColor] = useState('#FFFFFF');
  const isAdmin = useRouteMatch('/admin');
  const isClientes = useRouteMatch('/clientes');
  const isCotizaciones = useRouteMatch('/cotizaciones/:nombre');
  
  const isAdminOrCotizaciones = !(isAdmin || isCotizaciones || isClientes);

  const commonProps = { setHeaderLogoColor };

  return (
    <HeaderContextProvider>
      <ToastProvider>
        <Suspense fallback={<div />}>
          <ToastContainer />
          <ScrollToTop>
            {isAdminOrCotizaciones && (
              <Header color={headerLogoColor} isCotizaciones={isCotizaciones} />
            )}
            {isCotizaciones && <CotiHeader color={headerLogoColor} />}
            <Switch>
              <Route path="/" exact={true}>
                <Home {...commonProps} />
              </Route>
              <Route path="/aviso-de-privacidad">
                <AvisoPrivacidad {...commonProps} />
              </Route>
              <Route path="/nosotros" exact={true}>
                <Nosotros {...commonProps} />
              </Route>
              <Route path="/marketingdigital" exact={true}>
                <MarketingDigital {...commonProps} />
              </Route>
              <Route path="/abstractoplus">
                <AbstractoPlusRouter {...commonProps} />
              </Route>
              <Route path="/contacto" exact={true}>
                <Contacto {...commonProps} />
              </Route>
              <Route path="/cotizaciones/:nombre">
                <Cotizaciones {...commonProps} />
              </Route>
              <Route path="/clientes">
                <UserDataProvider>
                  <IntroClientes/>
                </UserDataProvider>
              </Route>
              <Route path="/admin/login" exact={true}>
                <Login />
              </Route>
              <Route path="/admin">
                <Intro />
              </Route>
              <Route exact path="*">
                <ErrorPage {...commonProps} />
              </Route>
            </Switch>
            {isAdminOrCotizaciones && <Footer />}
          </ScrollToTop>
        </Suspense>
      </ToastProvider>
    </HeaderContextProvider>
  );
}

function AbstractoPlusRouter(commonProps) {
  return (
    <PlusContext>
      <Switch>
        <Route path="/abstractoplus" exact>
          <AbstractoPlus {...commonProps} />
        </Route>
        <Route path="/abstractoplus/articulos/:urlArticulo">
          <Publicaciones {...commonProps} />
        </Route>
        <Route exact path="*">
          <ErrorPage {...commonProps} />
        </Route>
      </Switch>
    </PlusContext>
  );
}

export default App;
