import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyA2vNwyV3sh-i8-9w2tX5njJjZW3VuJrsE',
  authDomain: 'abstracto-2020.firebaseapp.com',
  databaseURL: 'https://abstracto-2020.firebaseio.com',
  projectId: 'abstracto-2020',
  storageBucket: 'abstracto-2020.appspot.com',
  messagingSenderId: '635404906718',
  appId: '1:635404906718:web:9ab8838374d210374f124f',
  measurementId: 'G-3HTSWT8LS5'
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
