const colors = {
  //Colores para textos e iconos
  naranja: '#fc5936',
  negro: '#000000',
  blanco: '#ffffff',

  //Colores
  background: '#f7f7f7',
  background2: '#e5e5e5',
  rojo: '#bd2d15',
  beige: '#efe2d2',
  marron: '#a85640',
  azulClaro: '#0bdae2',
  aqua: '#00e7c5',
  verdeOscuro: '#195e63',
  azul: '#25bac1',
  gris: '#d0cfcf',
  border: '#c4c4c4',
  menta: '#27e5c7'
};

const device = {
  mobile: `(max-width: 767px)`,
  tablet: `(min-width: 768px) and (max-width: 1151px)`,
  laptop: `(min-width: 1152px) and (max-width: 1439px)`,
  desktop: `(min-width: 1440px)`
};

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1440 },
    items: 3
  },
  laptop: {
    breakpoint: { max: 1439, min: 1152 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1151, min: 768 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1
  }
};

export { colors, device, responsive };
