export const getImgPathFirebase = (path, forceLogo = false) => {
  if (!forceLogo && (!path || path.slice(0, 4) === 'blob')) {
    return path;
  }
  return (
    'https://firebasestorage.googleapis.com/v0/b/abstracto-2020.appspot.com/o/images%2F' +
    (path ? path : 'Abstracto.jpg') +
    '?alt=media'
  );
};

export const tooltipGenerator = (str, replaceMap) => {
  let newString = str;
  if (!newString) return newString;
  Object.keys(replaceMap).map(word => {
    let descripcion = replaceMap[word].descripcion;
    let palabra = new RegExp(replaceMap[word].palabra, 'ig');
    return (newString = newString.replace(palabra, matchedWord => {
      return `<span class="tooltip-info" data-tip="${descripcion}">${matchedWord}</span>`;
    }));
  });
  return newString;
};

export const isMobile = () => {
  return window.innerWidth < 768;
};

export const dateFormatter = fecha =>
  `${fecha.getDate()}-${fecha.getMonth() + 1}-${fecha.getFullYear()}`;

const convertInDoubleDigit = number =>
  number.length === 1 ? '0' + number : number;

export const yyyymmmddFormatter = date => {
  const dateElement = new Date(date || '');
  const day = convertInDoubleDigit(dateElement.getDate().toString());
  const month = convertInDoubleDigit((dateElement.getMonth() + 1).toString());
  const year = dateElement.getFullYear().toString();
  return year + '-' + month + '-' + day;
};
