import React from 'react';
import classnames from 'classnames';

import { isMobile } from '../../utils';
import { INPUT_STATES, usePlus } from '../../../Contextos/PlusContext';

import './SearchInput.scss';

import lupa from '../../../assets/images/abstractoPlus/search.svg';

const { STALE, VIDEOS, ARTICULOS } = INPUT_STATES;

function SearchInput({ urlArticulo }) {
  const {
    inputState,
    setInputState,
    tipoContenido,
    setTipoContenido
  } = usePlus();

  const selectGrid = () =>
    classnames('seccion-grid', {
      'grid-seleccion-video': inputState === VIDEOS,
      'grid-seleccion-articulo': inputState === ARTICULOS,
      'grid-seleccion': inputState === STALE
    });

  const goBack = clickedMedia => {
    if (urlArticulo) {
      window.history.back();
    }
    // El siguiente setState tiene una variante cuando urlArticulo existe y no permite que exista STALE
    setInputState(
      inputState === clickedMedia && !urlArticulo ? STALE : clickedMedia
    );
    setTipoContenido(clickedMedia);
  };

  const isVideosExpanded =
    tipoContenido === VIDEOS || inputState === VIDEOS || !tipoContenido;
  const isArticlesExpanded =
    tipoContenido === ARTICULOS || inputState === ARTICULOS || !tipoContenido;

  return (
    <>
      <div className={!isMobile() ? selectGrid() : 'seccion-flex'}>
        <div
          className={classnames('videos', {
            'videos--expanded': isVideosExpanded,
            'videos--collapsed': !isVideosExpanded,
            'videos--full': isArticlesExpanded && isVideosExpanded
          })}
        >
          <span
            onClick={() => {
              goBack(VIDEOS);
            }}
          >
            <div>Videos</div>
          </span>
          {isMobile() && inputState === VIDEOS && (
            <InputBox busca={'videos'} clase={'input-search-mobile'} />
          )}
        </div>
        <div
          className={classnames('articulos', {
            'articulos--expanded': isArticlesExpanded,
            'articulos--collapsed': !isArticlesExpanded,
            'articulos--full': isArticlesExpanded && isVideosExpanded
          })}
        >
          <span
            onClick={() => {
              goBack(ARTICULOS);
            }}
          >
            Artículos
          </span>
          {isMobile() && inputState === ARTICULOS && (
            <InputBox busca={'artículos'} clase={'input-search-mobile'} />
          )}
        </div>
      </div>
      {!isMobile() && inputState === VIDEOS && (
        <div className="parent-input-search-tablet-videos">
          <InputBox busca={'videos'} clase={'input-search-tablet'} />
        </div>
      )}
      {!isMobile() && inputState === ARTICULOS && (
        <div className="parent-input-search-tablet-articulos">
          <InputBox busca={'artículos'} clase={'input-search-tablet'} />
        </div>
      )}
    </>
  );
}

function InputBox({ busca, clase }) {
  const { setInputValue, inputValue, inputState, setInputFocused } = usePlus();

  function onChange(e) {
    const textoABuscar = e.target.value;
    setInputValue(textoABuscar);
  }
  return (
    <div className="input-container">
      <input
        type="text"
        placeholder={`Buscar ${busca}`}
        className={clase}
        onChange={e => onChange(e)}
        onClick={() => setInputFocused(inputState)}
        value={inputValue}
      />
      <img src={lupa} alt="imagen de lupa" />
    </div>
  );
}

export default SearchInput;
