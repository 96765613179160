import arrowDown from '../assets/images/white-arrow-down.png';
import scrollText from '../assets/svgs/scroll.svg';
import thinkDifferent from '../assets/images/think-different.jpg';
import thinkDifferent768 from '../assets/images/think-different768.jpg';
import thinkDifferent1152 from '../assets/images/think-different1152.jpg';
import thinkDifferent1440 from '../assets/images/think-different1440.jpg';
import typewriter from '../assets/images/typewriter.jpg';
import typewriter768 from '../assets/images/typewriter768.jpg';
import typewriter1152 from '../assets/images/typewriter1152.jpg';
import typewriter1440 from '../assets/images/typewriter1440.jpg';
import elipses from '../assets/svgs/elipses.svg';
import blackArrowDown from '../assets/svgs/arrow-down.svg';
import threeDAbstracto from '../assets/svgs/a-3d.svg';
import threeDAbstracto1152 from '../assets/svgs/a-3d1152.svg';
import threeDAbstracto1440 from '../assets/svgs/a-3d1440.svg';
import developed from '../assets/svgs/developed.svg';
//4 imagenes
import abstracto1 from '../assets/images/abstracto/abstracto1.jpg';
import abstracto2 from '../assets/images/abstracto/abstracto2.jpg';
import abstracto3 from '../assets/images/abstracto/abstracto3.jpg';
import abstracto4 from '../assets/images/abstracto/abstracto4.jpg';
import abstracto1768 from '../assets/images/abstracto/abstracto1768.jpg';
import abstracto2768 from '../assets/images/abstracto/abstracto2768.jpg';
import abstracto3768 from '../assets/images/abstracto/abstracto3768.jpg';
import abstracto4768 from '../assets/images/abstracto/abstracto4768.jpg';
import abstracto11152 from '../assets/images/abstracto/abstracto11152.jpg';
import abstracto21152 from '../assets/images/abstracto/abstracto21152.jpg';
import abstracto31152 from '../assets/images/abstracto/abstracto31152.jpg';
import abstracto41152 from '../assets/images/abstracto/abstracto41152.jpg';
import abstracto11440 from '../assets/images/abstracto/abstracto11440.jpg';
import abstracto21440 from '../assets/images/abstracto/abstracto21440.jpg';
import abstracto31440 from '../assets/images/abstracto/abstracto31440.jpg';
import abstracto41440 from '../assets/images/abstracto/abstracto41440.jpg';
//Clientes
import clientes1 from '../assets/images/clientes/clientes1.png';
import clientes2 from '../assets/images/clientes/clientes2.png';
import clientes3 from '../assets/images/clientes/clientes3.png';
import clientes4 from '../assets/images/clientes/clientes4.png';
import clientes5 from '../assets/images/clientes/clientes5.png';
import clientes6 from '../assets/images/clientes/clientes6.png';
import clientes7 from '../assets/images/clientes/clientes7.png';
import clientes8 from '../assets/images/clientes/clientes8.png';
import clientes9 from '../assets/images/clientes/clientes9.png';
import clientes10 from '../assets/images/clientes/clientes10.png';
import clientes11 from '../assets/images/clientes/clientes11.png';
import clientes12 from '../assets/images/clientes/clientes12.png';
import logo3d from '../assets/svgs/abstracto-3d.svg';
import senor from '../assets/images/senor.png';
import lineaBlanca from '../assets/svgs/linea-blanca.svg';
import lineaNaranja from '../assets/svgs/linea.svg';
import oneDay from '../assets/images/one-day.png';
import abstractoIcon from '../assets/svgs/icono-abstracto.svg';
import abstractoIconAlt from '../assets/svgs/icono-abstracto-alt.svg';
import smallA from '../assets/svgs/smallA.svg';
//Contacto
import contacto from '../assets/images/contacto/contacto320px.jpg';
import contacto768 from '../assets/images/contacto/contacto768px.jpg';
import contacto1152 from '../assets/images/contacto/contacto1152px.jpg';
import contacto1440 from '../assets/images/contacto/contacto1440px.jpg';
//Nosotros
import elipsesHorizontal from '../assets/svgs/elipses-horizontal.svg';
import fotoDaniel from '../assets/images/nosotros/foto-daniel.jpg';
import nosotrosImagen1 from '../assets/images/nosotros/Nosotros_imagen1_320.jpg';
import nosotrosImagen1768 from '../assets/images/nosotros/Nosotros_imagen1_768.jpg';
import nosotrosImagen11152 from '../assets/images/nosotros/Nosotros_imagen1_1152.jpg';
import nosotrosImagen11440 from '../assets/images/nosotros/Nosotros_imagen1_1440.jpg';
import nosotrosImagen2 from '../assets/images/nosotros/Nosotros_imagen2_320.jpg';
import nosotrosImagen2768 from '../assets/images/nosotros/Nosotros_imagen2_768.jpg';
import nosotrosImagen21152 from '../assets/images/nosotros/Nosotros_imagen2_1152.jpg';
import nosotrosImagen21440 from '../assets/images/nosotros/Nosotros_imagen2_1440.jpg';
//Cotizaciones
import facebook from '../assets/svgs/fb-icon.svg';
import instagram from '../assets/svgs/ig-icon.svg';
import fAds from '../assets/svgs/f-ads.svg';
import fOrganico from '../assets/svgs/f-organico.svg';
import gAds from '../assets/svgs/google-ads.svg';
import gAnalytics from '../assets/svgs/google-analytics.svg';
import linkedin from '../assets/svgs/linkedin.svg';
import mailing from '../assets/svgs/mailing.svg';
import medium from '../assets/svgs/medium.svg';
import webinars from '../assets/svgs/webinars.svg';
import twitter from '../assets/svgs/twitter.svg';
import desarrolloWeb from '../assets/svgs/desarrollo-web.svg';
import multimedia from '../assets/svgs/multimedia.svg';
import otros from '../assets/svgs/otros.svg';
import iconHorizontal from '../assets/svgs/icono-abstracto-horizontal.svg';
//Admin
import chevronIcon from '../assets/svgs/admin/chevron-right.svg';
import chevronIconWhite from '../assets/svgs/admin/chevron-right_white.svg';
import chevronIconOrange from '../assets/svgs/admin/chevron-right_orange.svg';
import homeIcon from '../assets/svgs/admin/home.svg';
import homeIconWhite from '../assets/svgs/admin/home_white.svg';
import homeIconOrange from '../assets/svgs/admin/home_orange.svg';
import bookmark from '../assets/svgs/admin/bookmark.svg';
import bookmarkWhite from '../assets/svgs/admin/bookmark_white.svg';
import mail from '../assets/svgs/admin/mail.svg';
import mailWhite from '../assets/svgs/admin/mail_white.svg';
import paperclip from '../assets/svgs/admin/paperclip.svg';
import paperclipWhite from '../assets/svgs/admin/paperclip_white.svg';
import server from '../assets/svgs/admin/server.svg';
import serverWhite from '../assets/svgs/admin/server_white.svg';
import users from '../assets/svgs/admin/users.svg';
import usersWhite from '../assets/svgs/admin/users_white.svg';
import plusCircle from '../assets/svgs/admin/plus-circle.svg';
import plusCircleWhite from '../assets/svgs/admin/plus-circle_white.svg';
//PlataformaClientes
import Brief from '../assets/svgs/Clientes/Brief.svg'
import BriefOrange from '../assets/svgs/Clientes/Brief-orange.svg'
import Preguntas from '../assets/svgs/Clientes/Preguntas.svg'
import PreguntasOrange from '../assets/svgs/Clientes/Preguntas-orange.svg'
import Parrilla from '../assets/svgs/Clientes/Parrilla.svg'
import ParrillaOrange from '../assets/svgs/Clientes/Parrilla-orange.svg'
import Reportes from '../assets/svgs/Clientes/Reportes.svg'
import ReportesOrange from '../assets/svgs/Clientes/Reportes-orange.svg'
import Abstracto from '../assets/svgs/Clientes/Abstracto.svg'
import AbstractoOrange from '../assets/svgs/Clientes/Abstracto-orange.svg'
import Tendencias from '../assets/svgs/Clientes/Tendencias.svg'
import TendenciasOrange from '../assets/svgs/Clientes/Tendencias-orange.svg'
import Dudas from '../assets/svgs/Clientes/Dudas.svg'
import DudasOrange from '../assets/svgs/Clientes/Dudas-orange.svg'
import Leads from '../assets/svgs/Clientes/Leads.svg'
import LeadsOrange from '../assets/svgs/Clientes/Leads-orange.svg'
import Comentarios from '../assets/svgs/Clientes/Comentarios.svg'
import ComentariosOrange from '../assets/svgs/Clientes/Comentarios-orange.svg'
import Servicios from '../assets/svgs/Clientes/Servicios.svg'
import ServiciosOrange from '../assets/svgs/Clientes/Servicios-orange.svg'
import Minuta from '../assets/svgs/Clientes/Minuta.svg'
import MinutaOrange from '../assets/svgs/Clientes/Minuta-orange.svg'
import Calendario from '../assets/svgs/Clientes/Calendario.svg'
import CalendarioOrange from '../assets/svgs/Clientes/Calendario-orange.svg'
import Equipo from '../assets/svgs/Clientes/Equipo.svg'
import EquipoOrange from '../assets/svgs/Clientes/Equipo-orange.svg'
import Emergencia from '../assets/svgs/Clientes/Emergencia.svg'
import EmergenciaOrange from '../assets/svgs/Clientes/Emergencia-orange.svg'
import Facturacion from '../assets/svgs/Clientes/Facturacion.svg'
import FacturacionOrange from '../assets/svgs/Clientes/Facturacion-orange.svg'
import Contrato from '../assets/svgs/Clientes/Contrato.svg'
import ContratoOrange from '../assets/svgs/Clientes/Contrato-orange.svg'
import Cotizacion from '../assets/svgs/Clientes/Cotizacion.svg'
import CotizacionOrange from '../assets/svgs/Clientes/Cotizacion-orange.svg'
import Aviso from '../assets/svgs/Clientes/Aviso.svg'
import AvisoOrange from '../assets/svgs/Clientes/Aviso-orange.svg'
import Ojo from '../assets/svgs/Clientes/Ojo.svg'
import Ojocerrado from '../assets/svgs/Clientes/Ojo-cerrado.svg'
import PaginaWeb from '../assets/svgs/Clientes/PaginaWeb.svg'
import PaginaWebOrange from '../assets/svgs/Clientes/PaginaWeb-orange.svg'
import FotosVideos from '../assets/svgs/Clientes/FotosVideos.svg'
import FotosVideosOrange from '../assets/svgs/Clientes/FotosVideos-orange.svg'

export const returnImg = img => {
    switch (img) {
        case 'White-Arrow':
            return arrowDown;

        case 'Scroll':
            return scrollText;

        case 'Think':
            if (window.innerWidth > 1439) {
                return thinkDifferent1440;
            }
            if (window.innerWidth > 1151) {
                return thinkDifferent1152;
            }
            if (window.innerWidth > 767) {
                return thinkDifferent768;
            }
            return thinkDifferent;

        case 'Typewriter':
            if (window.innerWidth > 1439) {
                return typewriter1440;
            }
            if (window.innerWidth > 1151) {
                return typewriter1152;
            }
            if (window.innerWidth > 767) {
                return typewriter768;
            }
            return typewriter;

        case 'Elipses':
            return elipses;

        case 'ElipsesHorizontal':
            return elipsesHorizontal;

        case 'Black-Arrow':
            return blackArrowDown;

        case '3DAbstracto':
            if (window.innerWidth > 1439) {
                return threeDAbstracto1440;
            }
            if (window.innerWidth > 1151) {
                return threeDAbstracto1152;
            }
            return threeDAbstracto;

        case 'Abstracto1':
            if (window.innerWidth > 1439) {
                return abstracto11440;
            }
            if (window.innerWidth > 1151) {
                return abstracto11152;
            }
            if (window.innerWidth > 767) {
                return abstracto1768;
            }
            return abstracto1;

        case 'Abstracto2':
            if (window.innerWidth > 1439) {
                return abstracto21440;
            }
            if (window.innerWidth > 1151) {
                return abstracto21152;
            }
            if (window.innerWidth > 767) {
                return abstracto2768;
            }
            return abstracto2;

        case 'Abstracto3':
            if (window.innerWidth > 1439) {
                return abstracto31440;
            }
            if (window.innerWidth > 1151) {
                return abstracto31152;
            }
            if (window.innerWidth > 767) {
                return abstracto3768;
            }
            return abstracto3;

        case 'Abstracto4':
            if (window.innerWidth > 1439) {
                return abstracto41440;
            }
            if (window.innerWidth > 1151) {
                return abstracto41152;
            }
            if (window.innerWidth > 767) {
                return abstracto4768;
            }
            return abstracto4;

        case 'Clientes1':
            return clientes1;

        case 'Clientes2':
            return clientes2;

        case 'Clientes3':
            return clientes3;

        case 'Clientes4':
            return clientes4;

        case 'Clientes5':
            return clientes5;

        case 'Clientes6':
            return clientes6;

        case 'Clientes7':
            return clientes7;

        case 'Clientes8':
            return clientes8;

        case 'Clientes9':
            return clientes9;

        case 'Clientes10':
            return clientes10;

        case 'Clientes11':
            return clientes11;

        case 'Clientes12':
            return clientes12;

        case '3DLogo':
            return logo3d;

        case 'LineaBlanca':
            return lineaBlanca;

        case 'LineaNaranja':
            return lineaNaranja;

        case 'Señor':
            return senor;

        case 'Facebook':
            return facebook;

        case 'Instagram':
            return instagram;

        case 'OneDay':
            return oneDay;

        case 'IconAbstracto':
            return abstractoIcon;

        case 'IconAbstractoAlt':
            return abstractoIconAlt;

        case 'Small A':
            return smallA;

        case 'Developed':
            return developed;

        //Contacto
        case 'Contacto':
            if (window.innerWidth > 1439) {
                return contacto1440;
            }
            if (window.innerWidth > 1151) {
                return contacto1152;
            }
            if (window.innerWidth > 767) {
                return contacto768;
            }
            return contacto;

        //Nosotros
        case 'FotoDaniel':
            return fotoDaniel;

        case 'Nosotros1':
            if (window.innerWidth > 1439) {
                return nosotrosImagen11440;
            }
            if (window.innerWidth > 1151) {
                return nosotrosImagen11152;
            }
            if (window.innerWidth > 767) {
                return nosotrosImagen1768;
            }
            return nosotrosImagen1;

        case 'Nosotros2':
            if (window.innerWidth > 1439) {
                return nosotrosImagen21440;
            }
            if (window.innerWidth > 1151) {
                return nosotrosImagen21152;
            }
            if (window.innerWidth > 767) {
                return nosotrosImagen2768;
            }
            return nosotrosImagen2;

        //Cotizaciones
        case 'Twitter':
            return twitter;

        case 'Facebook Ads':
            return fAds;

        case 'Facebook orgánico':
            return fOrganico;

        case 'Google Ads':
            return gAds;

        case 'Google Analytics':
            return gAnalytics;

        case 'LinkedIn':
            return linkedin;

        case 'Mailing':
            return mailing;

        case 'Medium':
            return medium;

        case 'Webinars':
            return webinars;

        case 'Desarrollo Web':
            return desarrolloWeb;

        case 'Multimedia':
            return multimedia;

        case 'Otros':
            return otros;

        case 'IconHorizontal':
            return iconHorizontal;

        //Admin

        case 'Home':
            return homeIcon;

        case 'HomeWhite':
            return homeIconWhite;
            
        case 'HomeOrange':
            return homeIconOrange;

        case 'Chevron':
            return chevronIcon;

        case 'ChevronWhite':
            return chevronIconWhite;

        case 'ChevronOrange':
            return chevronIconOrange;

        case 'Bookmark':
            return bookmark;

        case 'BookmarkWhite':
            return bookmarkWhite;

        case 'Mail':
            return mail;

        case 'MailWhite':
            return mailWhite;

        case 'Paperclip':
            return paperclip;

        case 'PaperclipWhite':
            return paperclipWhite;

        case 'PlusCircle':
            return plusCircle;

        case 'PlusCircleWhite':
            return plusCircleWhite;

        case 'Server':
            return server;

        case 'ServerWhite':
            return serverWhite;

        case 'Users':
            return users;

        case 'UsersWhite':
            return usersWhite;

        case 'Brief':
            return Brief

        case 'BriefOrange':
            return BriefOrange
        
        case 'Preguntas':
            return Preguntas

        case 'PreguntasOrange':
            return PreguntasOrange

        case 'Parrilla':
            return Parrilla
        
        case 'ParrillaOrange':
            return ParrillaOrange
        
        case 'Reportes':
            return Reportes
        
        case 'ReportesOrange':
            return ReportesOrange

         case 'Abstracto':
            return Abstracto
        
        case 'AbstractoOrange':
            return AbstractoOrange
        
        case 'Tendencias':
            return Tendencias
        
        case 'TendenciasOrange':
            return TendenciasOrange

        case 'Dudas':
            return Dudas
        
        case 'DudasOrange':
            return DudasOrange

        case 'Leads':
            return Leads
        
        case 'LeadsOrange':
            return LeadsOrange

        case 'Comentarios':
            return Comentarios

        case 'ComentariosOrange':
            return ComentariosOrange
        
        case 'Servicios':
            return Servicios
        
        case 'ServiciosOrange':
            return ServiciosOrange
        
        case 'Minuta':
            return Minuta
        
        case 'MinutaOrange':
            return MinutaOrange
        
        case 'Calendario':
            return Calendario

        case 'CalendarioOrange':
            return CalendarioOrange

        case 'Equipo':
            return Equipo

        case 'EquipoOrange':
            return EquipoOrange

        case 'Emergencia':
            return Emergencia

        case 'EmergenciaOrange':
            return EmergenciaOrange

        case 'Facturacion':
            return Facturacion

        case 'FacturacionOrange':
            return FacturacionOrange
    
        case 'Contrato':
            return Contrato
        
        case 'ContratoOrange':
            return ContratoOrange

        case 'Cotizacion':
            return Cotizacion

        case 'CotizacionOrange':
            return CotizacionOrange

        case 'Aviso':
            return Aviso

        case 'AvisoOrange':
            return AvisoOrange

        case 'Ojo':
            return Ojo
        
        case 'OjoCerrado':
            return Ojocerrado
        
        case 'PaginaWeb':
            return PaginaWeb
        
        case 'PaginaWebOrange':
            return PaginaWebOrange
        
        case 'FotosVideos':
            return FotosVideos
        
        case 'FotosVideosOrange':
            return FotosVideosOrange

        default:
            return img;
    }
};