import React, { useMemo, useRef } from 'react';
import { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../utils/firebase';

const Plus = createContext();

export const usePlus = () => {
  return useContext(Plus);
};

export const PlusContext = ({ children }) => {
  const { VIDEOS, ARTICULOS } = INPUT_STATES;

  const [categoriaActiva, setCategoriaActiva] = useState();
  const [tipoContenido, setTipoContenido] = useState('');

  const [inputState, setInputState] = useState(INPUT_STATES.STALE);
  const [inputFocused, setInputFocused] = useState();
  const [inputValue, setInputValue] = useState('');
  const [articulosList, setArticulosList] = useState([]);
  const [videosList, setVideoList] = useState([]);
  const fetchedVideos = useRef(false);
  const fetchedBlogs = useRef(false);
  const [filteredArticulos, setFilteredArticulos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);

  const [categoriasVideos, setCategoriasVideos] = useState({});
  const [categoriasBlog, setCategoriasBlog] = useState({});
  const [generalData, setGeneralData] = useState({});

  useEffect(() => {
    const unsuscribe = db
      .collection('abstracto+')
      .onSnapshot(function (querySnapshot) {
        let abstractoPlusDB = {};
        querySnapshot.forEach(doc => (abstractoPlusDB[doc.id] = doc.data()));
        setCategoriasBlog(abstractoPlusDB?.blog?.categorias);
        setCategoriasVideos(abstractoPlusDB?.videos?.categorias);
        setGeneralData(abstractoPlusDB?.blog);
      });
    return unsuscribe;
  }, []);

  useEffect(() => {
    if (!fetchedBlogs.current && inputFocused === ARTICULOS) {
      fetchedBlogs.current = true;
      db.collection('posts')
        .get()
        .then(articulosFromDB => {
          const articulos = [];
          articulosFromDB.forEach(articulo => articulos.push(articulo.data()));
          setArticulosList(articulos);
        });
    } else if (!fetchedVideos.current && inputFocused === VIDEOS) {
      fetchedVideos.current = true;
      db.collection('videos')
        .get()
        .then(videosFromDB => {
          const videos = [];
          videosFromDB.forEach(video => videos.push(video.data()));
          setVideoList(videos);
        });
    }
  }, [inputFocused, ARTICULOS, VIDEOS]);

  const searchEntries = useMemo(() => {
    if (!inputValue) {
      return [];
    }
    const lista = inputFocused === ARTICULOS ? articulosList : videosList;
    debugger;
    return lista?.filter(element =>
      element?.titulo.toLowerCase().includes(inputValue.toLowerCase())
    );
  }, [articulosList, videosList, inputValue, ARTICULOS, inputFocused]);

  useEffect(() => {
    const setter =
      inputFocused === ARTICULOS ? setFilteredArticulos : setFilteredVideos;
    setter(searchEntries);
  }, [
    ARTICULOS,
    VIDEOS,
    articulosList,
    videosList,
    inputFocused,
    searchEntries
  ]);

  return (
    <Plus.Provider
      value={{
        categoriaActiva,
        setCategoriaActiva,
        inputState,
        setInputState,
        tipoContenido,
        setTipoContenido,
        categoriasBlog,
        setCategoriasBlog,
        categoriasVideos,
        setCategoriasVideos,
        setInputFocused,
        setInputValue,
        inputValue,
        filteredVideos,
        filteredArticulos,
        generalData
      }}
    >
      {children}
    </Plus.Provider>
  );
};

export const INPUT_STATES = {
  STALE: null,
  VIDEOS: 'Videos',
  ARTICULOS: 'Articulos'
};
