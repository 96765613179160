import styled from 'styled-components';

import { device, colors } from '../../utils/exports';

//GradientWrappers
const Container = styled.section`
  width: 100%;
`;

const IntroGradient = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(142, 177, 173, 1) 50%,
    rgba(201, 120, 99, 1) 100%
  );
`;

const NosotrosGradient = styled.div`
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(201, 120, 99, 1) 0%,
    rgba(254, 70, 35, 1) 51%,
    rgba(247, 169, 155, 1) 100%
  );
`;

const TypewriterGradient = styled.div`
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(247, 169, 155, 1) 0%,
    rgba(247, 247, 247, 1) 45%
  );
`;

//HeroWrapper
const HeroWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  .hero {
    position: absolute;
    width: 233px;
    height: 216px;
    right: 20px;
    top: 340px;
    @media ${device.tablet} {
      width: 450px;
      height: 416px;
      right: 40px;
      top: 400px;
    }
    @media ${device.laptop} {
      width: 350px;
      height: 316px;
      right: 222px;
      top: 300px;
    }
    @media ${device.desktop} {
      width: 450px;
      height: 415px;
      right: 258px;
      top: 300px;
    }
  }
  img {
    box-shadow: none;
    border-radius: 0;
    margin: 0 auto;
    &.scroll {
      width: 16px;
      margin-bottom: 12px;
    }
    &.arrow {
      width: 12px;
      height: 40px;
    }
    &.think {
      z-index: 1;
      margin: 0 auto 30px;
      width: calc(100% - 32px);
      @media ${device.tablet} {
        width: 307px;
        margin: 0 auto 50px;
        right: 0;
      }
      @media ${device.laptop} {
        width: 366px;
        margin: 0 auto 50px;
      }
      @media ${device.desktop} {
        width: 450px;
        margin: 0 auto 50px;
      }
    }
    &.typewriter {
      margin: 0 auto;
      width: 100%;
      @media ${device.tablet} {
        width: 372px;
        margin: 0;
      }
      @media ${device.laptop} {
        position: absolute;
        width: 564px;
        margin: 0;
      }
      @media ${device.desktop} {
        position: absolute;
        width: 707px;
        margin: 0;
      }
    }
  }
  @media ${device.laptop} {
    #nosotros {
      overflow: hidden;
    }
  }
  @media ${device.desktop} {
    #nosotros {
      overflow: hidden;
    }
  }
`;
const HeroTitle = styled.h1`
  width: auto;
  margin: 0 16px;
  padding-top: 174px;
  padding-bottom: 30px;
  color: ${colors.blanco};
  @media ${device.tablet} {
    margin: 0 129px;
    padding-top: 191px;
    padding-bottom: 100px;
  }
  @media ${device.laptop} {
    width: 610px;
    margin: 0 auto 0 344px;
    padding-top: 191px;
    padding-bottom: 100px;
  }
  @media ${device.desktop} {
    width: 573px;
    margin: 0 auto 0 574px;
    padding-top: 191px;
    padding-bottom: 100px;
  }
`;
const H1TitleRight = styled(HeroTitle)`
  padding-top: 0;
  text-align: right;
  margin-bottom: 28px;
  margin-top: 300px;
  @media ${device.tablet} {
    margin: 0;
    padding: 22px 0 28px;
  }
  @media ${device.laptop} {
    width: 366px;
    margin: 217px auto 28px;
    padding: 0;
  }
  @media ${device.desktop} {
    width: 450px;
    margin: 329px auto 28px;
    padding: 0;
  }
`;
const H1Black = styled(HeroTitle)`
  padding: 0;
  margin-top: 28px;
  margin-bottom: 44px;
  color: ${colors.negro};
  text-align: center;
  @media ${device.tablet} {
    padding: 28px 105px 32px 0;
    text-align: left;
  }
  @media ${device.laptop} {
    width: 244px;
    height: 100px;
    padding: 100px 0 64px;
    text-align: left;
  }
  @media ${device.desktop} {
    width: 347px;
    height: 44px;
    padding: 100px 0 64px;
    text-align: left;
  }
`;
const HeaderText = styled.h2`
  width: auto;
  margin: 0 16px 44px;
  color: ${colors.blanco};
  @media ${device.tablet} {
    margin: 0 129px 64px;
  }
  @media ${device.laptop} {
    margin: 0;
    width: 390px;
  }
  @media ${device.desktop} {
    margin: 0;
    width: 633px;
  }
`;
const HeaderTextBlack = styled(HeaderText)`
  color: ${colors.negro};
  margin-top: 40px;
  text-align: center;
  @media ${device.tablet} {
    margin: 0;
    margin-right: 105px;
    padding-top: 22px;
    text-align: left;
  }
  @media ${device.laptop} {
    width: 244px;
    margin: 0;
    margin-top: 124px;
    text-align: left;
  }
  @media ${device.desktop} {
    width: 347px;
    margin: 0;
    margin-top: 266px;
    text-align: left;
  }
`;
const ScrollBounce = styled.div`
  width: auto;
  margin: 0 16px;
  height: auto;
  @media ${device.tablet} {
    margin: 0 129px;
  }
  @media ${device.laptop} {
    margin: 0 auto;
    width: 756px;
  }
  @media ${device.desktop} {
    margin: 0 auto;
    width: 924px;
  }
`;
const VerticalWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: calc(100vh - 564px);
  @media ${device.tablet} {
    padding-top: calc(100vh - 664px);
  }
  @media ${device.laptop} {
    padding-top: calc(100vh - 664px);
  }
  @media ${device.desktop} {
    padding-top: calc(100vh - 664px);
  }
`;
const AvenirText = styled.p`
  margin: 0 16px;
  width: auto;
  text-align: right;
  font-family: 'Avenir LT Std';
  font-weight: 500;
  font-size: 20px;
  color: ${colors.blanco};
  line-height: 30px;
  margin-bottom: 40px;
  @media ${device.tablet} {
    margin: 0;
  }
  @media ${device.laptop} {
    margin: 0;
  }
  @media ${device.desktop} {
    margin: 0;
  }
`;
const LineContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 0;
  .think {
    z-index: 1;
    margin: 0 auto;
  }
  .linea {
    width: 421px;
    left: 64%;
    bottom: 0px;
    box-shadow: none;
    position: absolute;
    @media ${device.tablet} {
      left: 60%;
    }
    @media ${device.laptop} {
      left: 40%;
      z-index: 10;
      bottom: -2px;
    }
    @media ${device.desktop} {
      left: 50%;
      z-index: 10;
      width: 558px;
      bottom: -16px;
    }
  }
  @media ${device.tablet} {
    h1 {
      margin: 0;
    }
  }
  @media ${device.laptop} {
    overflow: unset;
    h1 {
      margin: 0;
    }
  }
  @media ${device.desktop} {
    overflow: unset;
    h1 {
      margin: 0;
      margin-bottom: 13px;
    }
  }
`;
const ThinkDifferent = styled.div`
  padding-bottom: 100px;
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 1fr 307px;
    grid-column-gap: 65px;
    margin: 258px 0 0 129px;
  }
  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 366px 366px;
    grid-column-gap: 24px;
    margin: 0 auto;
    width: 756px;
  }
  @media ${device.desktop} {
    display: grid;
    grid-template-columns: 450px 450px;
    grid-column-gap: 24px;
    margin: 0 auto;
    width: 924px;
  }
`;
const Typewriter = styled.div`
  .elipses {
    display: none;
    @media ${device.tablet} {
      grid-column: span 2;
      display: block;
      margin: -35px auto 0 337px;
      width: 70px;
      height: 70px;
    }
    @media ${device.laptop} {
      position: absolute;
      display: block;
      width: 126px;
      height: 126px;
      margin: 0;
      left: 500px;
    }
    @media ${device.desktop} {
      position: absolute;
      display: block;
      width: 126px;
      height: 126px;
      margin: 0;
      left: 645px;
    }
  }
  .boton {
    width: fit-content;
    margin: 0 auto;
  }
  @media ${device.tablet} {
    width: 100%;
    display: grid;
    grid-template-columns: 372px 1fr;
    grid-column-gap: 24px;
    margin: 0 129px 0 0;
    padding-top: 204px;
    h1 {
      margin: 0;
    }
    .boton {
      width: 100%;
      margin: 0 auto;
    }
  }
  @media ${device.laptop} {
    width: 100%;
    margin: 0;
    padding-top: 204px;
    .boton {
      margin: 0;
      width: 244px;
    }
    h1 {
      margin: 0;
      width: 244px;
    }
    .info {
      width: 756px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-bottom: 35px;
    }
  }
  @media ${device.desktop} {
    width: 100%;
    margin: 0;
    padding-top: 189px;
    .boton {
      margin: 0;
      width: 347px;
    }
    h1 {
      margin: 0;
      width: 347px;
    }
    .info {
      width: 924px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-bottom: 225px;
    }
  }
`;
//abstracto+
const AbstractoPlus = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  @media ${device.tablet} {
    margin-top: 199px;
  }
  @media ${device.laptop} {
    margin-top: 328px;
  }
  @media ${device.desktop} {
    margin-top: 334px;
  }
  .arrow {
    width: 12px;
    height: 40px;
  }
  .mobile {
    display: none;
    box-shadow: none;
    border-radius: 0;
    @media ${device.mobile} {
      display: block;
    }
  }
  .tablet {
    box-shadow: none;
    border-radius: 0;
    @media ${device.mobile} {
      display: none;
    }
    @media ${device.tablet} {
      display: block;
      padding-top: 220px;
    }
    @media ${device.laptop} {
      display: block;
      padding-top: 210px;
    }
    @media ${device.desktop} {
      display: block;
      padding-top: 250px;
    }
  }
`;
const AbstractoGrid = styled.div`
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 1fr 202px;
    grid-column-gap: 65px;
    margin: 0 105px 0 129px;
    .arrow {
      grid-column: span 2;
      margin-top: 32px;
    }
  }
  @media ${device.laptop} {
    display: grid;
    width: 756px;
    grid-template-columns: 1fr 244px;
    grid-column-gap: 61px;
    margin: 0 auto;
    .arrow {
      margin-top: -39px;
    }
  }
  @media ${device.desktop} {
    display: grid;
    width: 924px;
    grid-template-columns: 1fr 291px;
    grid-column-gap: 182px;
    margin: 0 auto;
    .arrow {
      margin-top: -83px;
    }
  }
`;
const HeaderAbstracto = styled(H1Black)`
  margin-top: 0;
  margin-bottom: 28px;
  text-align: left;
  @media ${device.tablet} {
    margin: 0;
    margin-bottom: 28px;
    padding: 0;
  }
  @media ${device.laptop} {
    margin: 0;
    margin-bottom: 28px;
    padding: 0;
    height: unset;
  }
  @media ${device.desktop} {
    margin: 0;
    margin-bottom: 28px;
    padding: 0;
  }
`;
const AvenirAbstracto = styled(AvenirText)`
  margin-bottom: 28px;
  color: ${colors.negro};
  text-align: left;
`;
const GiantA = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 16px;
  width: auto;
  justify-content: space-between;
  @media ${device.tablet} {
    margin: 0;
    justify-content: center;
    align-items: flex-start;
  }
  @media ${device.mobile} {
    justify-content: flex-end;
  }
  @media ${device.laptop} {
    margin: 0;
  }
  @media ${device.desktop} {
    margin: 0;
  }
  svg {
    width: 202px;
    height: 184px;
    @media ${device.laptop} {
      width: 245px;
      height: 224px;
    }
    @media ${device.desktop} {
      width: 292px;
      height: 268px;
    }
  }
  img {
    box-shadow: none;
    border-radius: 0;
    &.arrow {
      align-self: flex-start;
    }
  }
`;
//Images
const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1;
  width: auto;
  margin: 28px 0 0;
  position: relative;
  overflow: hidden;
  @media ${device.tablet} {
    margin: 64px 0 0;
  }
  @media ${device.laptop} {
    margin: 64px 0 0;
  }
  @media ${device.laptop} {
    margin: 21px 0 0;
  }
  img {
    width: 100%;
    border-radius: 0;
    margin-bottom: 12px;
    z-index: 1;
  }
  h1 {
    margin-top: 32px;
    margin-bottom: 100px;
    z-index: 1;
  }
  .linea {
    position: absolute;
    box-shadow: none;
    bottom: -250px;
    width: 733px;
    left: 40px;
    z-index: -1;
    @media ${device.tablet} {
      left: 263px;
      bottom: -449px;
    }
    @media ${device.laptop} {
      left: 450px;
      bottom: -464px;
    }
    @media ${device.desktop} {
      left: 582px;
      bottom: -440px;
    }
  }
`;
const ImageGrid = styled.div`
  @media ${device.mobile} {
    .a3,
    .a2,
    .a1 {
      box-shadow: none;
    }
  }
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr auto;
    position: relative;
    height: 668px;
    width: 510px;
    margin: 0 auto 232px;
    .a1 {
      width: 297px;
      left: 0;
      z-index: 1;
    }
    .a2 {
      width: 333px;
      right: 0;
      top: 51px;

      z-index: 2;
    }
    .a3 {
      width: 360px;
      left: 0;
      top: 77px;

      z-index: 4;
    }
    .a4 {
      width: 289px;
      right: 0;
      top: -28px;

      z-index: 3;
    }
    h1 {
      grid-column: span 2;
      margin: 0;
      margin-bottom: -50px;
    }
  }
  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr auto;
    position: relative;
    height: 958px;
    width: 756px;
    margin: 0 auto 232px;
    .a1 {
      width: 442px;
      left: 0;
      z-index: 1;
    }
    .a2 {
      width: 496px;
      right: 0;
      top: 76px;

      z-index: 2;
    }
    .a3 {
      width: 536px;
      left: 0;
      top: 106px;

      z-index: 4;
    }
    .a4 {
      width: 429px;
      right: 0;
      top: -48px;

      z-index: 3;
    }
    h1 {
      grid-column: span 2;
      margin: 0;
      margin-bottom: -30px;
    }
  }
  @media ${device.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr auto;
    position: relative;
    height: 987px;
    width: 924px;
    margin: 0 auto 232px;
    .a1 {
      width: 442px;
      left: 0;
      z-index: 1;
    }
    .a2 {
      width: 496px;
      right: 0;
      top: 76px;

      z-index: 2;
    }
    .a3 {
      width: 536px;
      left: 0;
      top: 106px;

      z-index: 4;
    }
    .a4 {
      width: 429px;
      right: 0;
      top: -48px;

      z-index: 3;
    }
    h1 {
      grid-column: span 2;
      margin: 0;
      margin-bottom: -17px;
    }
  }
`;
const ImgCenter = styled.div`
  width: auto;
  margin: 0 16px;
  position: relative;
  img {
    height: auto;
  }
  @media ${device.tablet} {
    margin: 0;
    img {
      margin: 0;
      position: absolute;
    }
  }
  @media ${device.laptop} {
    margin: 0;
    img {
      margin: 0;
      position: absolute;
    }
  }
  @media ${device.desktop} {
    margin: 0;
    img {
      margin: 0;
      position: absolute;
    }
  }
`;
//Carrusel Clientes
const Clientes = styled.div`
  width: 100%;
  img {
    box-shadow: none;
  }
  .carrusel {
    .react-multi-carousel-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media ${device.mobile} {
      margin: 0 19px;
      .carrusel-img {
        width: 180px;
        height: auto;
      }
    }
    @media ${device.tablet} {
      margin: 0 108px;
      .carrusel-img {
        width: 187px;
        height: auto;
      }
    }
    @media ${device.laptop} {
      margin: 0 165px;
      .carrusel-img {
        width: 187px;
        height: auto;
      }
    }
    @media ${device.desktop} {
      margin: 0 238px;
      .carrusel-img {
        width: 220px;
        height: auto;
      }
    }
  }
  .custom-left-arrow {
    position: absolute !important;
    z-index: 11;
    display: inline-block;
    padding: 6px;
    cursor: pointer;
  }
  .custom-right-arrow {
    position: absolute !important;
    right: 0;
    z-index: 11;
    display: inline-block;
    padding: 6px;
    cursor: pointer;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
`;
const HeaderClientes = styled(H1Black)`
  margin-top: 0;
  margin-bottom: 40px;
  text-align: left;
  @media ${device.tablet} {
    padding-top: 0;
    padding-bottom: 100px;
  }
  @media ${device.laptop} {
    padding-top: 0;
    padding-bottom: 100px;
    width: 756px;
    margin: 0 auto;
  }
  @media ${device.desktop} {
    padding-top: 0;
    padding-bottom: 100px;
    width: 924px;
    margin: 0 auto;
  }
`;
const ImgWrap = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    border-radius: 0;
    box-shadow: none;
  }
`;

//Senor
const Senor = styled.section`
  margin-top: 100px;
  width: 100%;
  @media ${device.laptop} {
    margin-top: 200px;
  }
  @media ${device.desktop} {
    margin-top: 200px;
  }
  .logo {
    width: 100%;
    margin: 0 16px;
    box-shadow: none;
    border-radius: 0;
    @media ${device.tablet} {
      margin: 0 128px;
    }
    @media ${device.laptop} {
      margin: 0 auto;
      width: 511px;
    }
    @media ${device.desktop} {
      margin: 0 auto;
      width: 607px;
    }
  }
  .senor {
    width: 68px;
    height: 126px;
    margin: 123px auto 0;
    box-shadow: none;
    border-radius: 0;
    @media ${device.tablet} {
      margin-top: 167px;
    }
    @media ${device.laptop} {
      margin-top: 167px;
    }
    @media ${device.desktop} {
      margin-top: 167px;
    }
  }
`;
//Extras
const FixedSide = styled.section`
  width: 198px;
  height: 100vh;
  position: fixed;
  top: 0;
  display: none;
  z-index: 5;
  pointer-events: none;
  cursor: none;
  @media ${device.laptop} {
    display: block;
  }
  @media ${device.desktop} {
    display: block;
    width: 258px;
  }
`;
const FixedLeft = styled(FixedSide)`
  left: 0;
  @media ${device.tablet} {
    display: block;
    width: 129px;
  }
`;
const FixedRight = styled(FixedSide)`
  right: 0;
`;
const FlexWrap = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.laptop} {
    flex-direction: row;
    width: 756px;
    margin: 0 auto 200px;
    justify-content: space-between;
    align-items: center;
  }
  @media ${device.desktop} {
    flex-direction: row;
    width: 924px;
    margin: 0 auto 200px;
    justify-content: space-between;
    align-items: center;
  }
`;
const LeftSideFlexWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 22px;
  margin-left: 100px;
  margin-right: 76px;
  height: fit-content;
  bottom: 55px;
  position: fixed;
  img {
    box-shadow: none;
    border-radius: 0;
    &.one-day {
      margin-bottom: 10px;
    }
    &.icono {
      margin-bottom: 105px;
    }
  }
  .fb {
    margin-bottom: 36px;
    cursor: pointer;
  }
  .ig {
    cursor: pointer;
  }
  @media ${device.desktop} {
    margin-right: 136px;
  }
  @media ${device.tablet} {
    margin-left: 40px;
    bottom: 180px;
    transition: 0.25s linear;
  }
`;
const RightSideFlexWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 132px;
  margin-right: 100px;
  margin-left: 55px;
  height: fit-content;
  position: fixed;
  bottom: 340px;
  img {
    box-shadow: none;
    border-radius: 0;
  }
  @media ${device.desktop} {
    margin-left: 136px;
  }
`;

const Section = styled.p`
  font-size: 16px;
  line-height: 19px;
  font-family: 'Avenir LT Std';
  transform: rotate(-90deg);
  color: ${props => props.color};
  transition: 0.5s linear;
`;

const SectionLeft = styled(Section)`
  width: 159px;
  height: 159px;
`;

export {
  Container,
  IntroGradient,
  NosotrosGradient,
  TypewriterGradient,
  HeroWrapper,
  HeroTitle,
  H1TitleRight,
  H1Black,
  HeaderText,
  HeaderTextBlack,
  ScrollBounce,
  VerticalWrapper,
  AvenirText,
  LineContainer,
  ThinkDifferent,
  Typewriter,
  AbstractoPlus,
  AbstractoGrid,
  HeaderAbstracto,
  AvenirAbstracto,
  GiantA,
  ImageContainer,
  ImageGrid,
  ImgCenter,
  Clientes,
  HeaderClientes,
  ImgWrap,
  Senor,
  FixedLeft,
  FixedRight,
  FlexWrap,
  LeftSideFlexWrap,
  RightSideFlexWrap,
  Section,
  SectionLeft
};
