import React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import { Link } from 'react-router-dom';

import Logo from '../../../../assets/svgs/Logo';

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildern: 0.2 },
    backgroundColor: "white",
    opacity: 1,
    display: "block"
  },
  closed: {
    transition: { staggerChildern: 0.05, staggerDirection: -1 },
    backgroundColor: "transparent",
    opacity: 0,
  }
};

export const Navigation = ({ toggle }) => (
  <motion.ul variants={variants} className="menu-list-vert hidden">
    <Link to="/">
      <Logo toggle={toggle} />
    </Link>
    <hr />
    {itemIds.map((i) => {
      let link = i;
      if (i === "marketing digital.") {
        link = "marketingdigital."
      }
      return (
        <a href={`#${link}`} key={i} onClick={toggle}>
          <div>
            <MenuItem i={i} />
          </div>
        </a>
      );
    })}
  </motion.ul>
);

const itemIds = ["Objetivo", "Metodología", "Plataformas", "Reportes", "Calendario", "Responsabilidades", "Costo", "Contacto"];
