import React, { useCallback, useEffect, useRef, useState } from 'react';
import { colors } from '../../utils/exports';
import 'animate.css/animate.min.css';
import Tilt from 'react-tilt';

import {
  Container,
  FixedLeft,
  FixedRight,
  FlexWrap,
  LeftSideFlexWrap,
  RightSideFlexWrap,
  Section,
  SectionLeft
} from '../Home/HomeComponents';
import SmallA from '../../assets/svgs/SmallA';
import { Link } from 'react-router-dom';
import IGIcon from '../../assets/svgs/IGIcon';
import FBIcon from '../../assets/svgs/FBIcon';
import { returnImg } from '../../utils/returnImg';
import {
  ButtonText,
  Historia,
  LinkButton,
  TextoHistoria
} from '../Nosotros/NosotrosComponents';
import ScrollAnimation from 'react-animate-on-scroll';
import Arrow from '../../assets/svgs/Arrow';
import Abstracto3D from '../../assets/svgs/Abstracto3D';
import socialMedia from '../../assets/svgs/socialMedia.svg';
import web from '../../assets/svgs/web.svg';
import produccionCreativa from '../../assets/svgs/produccionCreativa.svg';
import analisisYEstrategia from '../../assets/svgs/analisisYEstrategia.svg';
import './MarketingDigital.scss';
import { db } from '../../utils/firebase';

function MarketingDigital({ setHeaderLogoColor }) {
  const shownFooter = useRef(false);
  const historiaRef = useRef();
  const [logoShow, setLogoShow] = useState('hidden');
  const [sideColor, setSideColor] = useState(colors.negro);
  const [redesColor, setRedesColor] = useState(colors.negro);
  const [aColor, setAColor] = useState(colors.blanco);
  const [section, setSection] = useState('marketing digital');
  const [marketingData, setmarketingData] = useState();

  const handleScroll = useCallback(() => {
    if (!shownFooter.current) {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 1
      ) {
        setTimeout(
          () => (document.getElementById('footer').style.display = 'block'),
          1
        );
        shownFooter.current = true;
      }
    }
    const bounding = historiaRef.current.getBoundingClientRect();
    let bottomThreshold;
    let topThreshold;

    //1 Define bottomThreshold y topThreshold de cada size
    // Laptop
    if (window.innerWidth > 1151 && window.innerWidth < 1439) {
      bottomThreshold = window.innerHeight - 290;
      topThreshold = 180;
    }
    // Tablet
    else if (window.innerWidth > 767 && window.innerWidth < 1151) {
      bottomThreshold = window.innerHeight - 404;
      topThreshold = 150;
    }
    // Desktop
    else if (window.innerWidth > 1439) {
      bottomThreshold = window.innerHeight - 300;
      topThreshold = 180;
    }
    // Mobile
    else if (window.innerWidth < 768) {
      topThreshold = 60;
      bottomThreshold = window.innerHeight - 300;
    }
    //2 Círculos barra lateral izquierda
    if (bounding.top <= 300 && bounding.bottom <= bottomThreshold) {
      if (section === 'marketing digital') {
        setSection('contacto');
      }
    } else {
      if (section === 'contacto') {
        setSection('marketing digital');
      }
    }
    //3 Logo abstracto 3D
    if (bounding.top < topThreshold && bounding.bottom > 250) {
      setLogoShow('visible');
    } else {
      setLogoShow('hidden');
    }
    //4 Logos FB e Insta
    if (bounding.top > -50) {
      setRedesColor(colors.negro);
    } else {
      setRedesColor(colors.blanco);
    }
    //5 one day? or day one?
    if (bounding.top > -200) {
      setSideColor(colors.negro);
    } else {
      setSideColor(colors.blanco);
    }
    //6 Color Header
    if (bounding.top < 100) {
      if (bounding.top <= -750 && bounding.bottom <= bottomThreshold) {
        setAColor(colors.blanco);
        setHeaderLogoColor(colors.negro);
      } else {
        setAColor(colors.negro);
        setHeaderLogoColor(colors.negro);
      }
    } else {
      if (
        window.document.querySelector('#fondo-padre').getBoundingClientRect()
          .top === 0
      ) {
        setHeaderLogoColor(colors.negro);
      } else {
        setHeaderLogoColor(colors.negro);
        setAColor(colors.negro);
      }
    }
  }, [section, setHeaderLogoColor]);

  useEffect(() => {
    db.collection('marketing').onSnapshot(function (querySnapshot) {
      const marketingDB = {};
      querySnapshot.forEach(function (doc) {
        marketingDB[doc.id] = doc.data();
        if (doc.id !== 'General') {
          marketingDB[doc.id].listado =
            '<li>' + doc.data().listado.replaceAll('\n', '</li><li>') + '</li>';
        }
      });
      setmarketingData(marketingDB);
    });
  }, []);

  useEffect(() => {
    setHeaderLogoColor(colors.negro);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setHeaderLogoColor, handleScroll]);

  return (
    <>
      <Container>
        <Link to="/">
          <SmallA color={aColor} />
        </Link>
        <FixedLeft>
          <LeftSideFlexWrap id="social-networks">
            <SectionLeft color={sideColor}>one day? or day one?</SectionLeft>
            {section === 'marketing' ? (
              <img
                className="icono"
                src={returnImg('IconAbstractoAlt')}
                alt="Icono Abstracto"
              />
            ) : (
              <img className="icono" src={returnImg('IconAbstracto')} alt="" />
            )}
            <a
              href="https://www.facebook.com/Abstracto-109168160795762?_rdc=1&_rdr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FBIcon color={redesColor} />
            </a>
            <a
              href="https://www.instagram.com/abstracto.mexico/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IGIcon color={redesColor} />
            </a>
          </LeftSideFlexWrap>
        </FixedLeft>
        <FixedRight>
          <RightSideFlexWrap>
            <Section color={sideColor}>{section}</Section>
          </RightSideFlexWrap>
        </FixedRight>
        <main id="main-marketing-digital">
          <div id="fondo-padre">
            <div className="primera-seccion">
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={1.5}
                animateOnce={true}
              >
                <h2>{marketingData?.General?.titulo}</h2>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={1.5}
                animateOnce={true}
              >
                <p>{marketingData?.General?.descripcion}</p>
              </ScrollAnimation>
            </div>
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
              <img
                src={returnImg('ElipsesHorizontal')}
                alt="Elipses"
                className="elipses"
              />
            </ScrollAnimation>
            <div className="segunda-seccion">
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={1.5}
                animateOnce={true}
              >
                <p>{marketingData?.General?.listado}</p>
              </ScrollAnimation>
            </div>
            <div className="primer-grid">
              <Tilt
                options={{ max: 30, scale: 1 }}
                style={{ zIndex: 1, padding: 0 }}
              >
                <div className="naranja">
                  <img
                    src={socialMedia}
                    alt="Logo de Social Media"
                    className="image-primer-grid"
                  />
                  <span>{marketingData?.Servicio1?.titulo}</span>
                </div>
              </Tilt>
              <Tilt
                options={{ max: 30, scale: 1 }}
                style={{ zIndex: 1, padding: 0 }}
              >
                <div className="aqua">
                  <img
                    src={web}
                    alt="Logo de Web"
                    className="image-primer-grid"
                  />
                  <span>{marketingData?.Servicio2?.titulo}</span>
                </div>
              </Tilt>
              <Tilt
                options={{ max: 30, scale: 1 }}
                style={{ zIndex: 1, padding: 0 }}
              >
                <div className="verde">
                  <img
                    src={produccionCreativa}
                    alt="Logo de Producción Creativa"
                    className="image-primer-grid"
                  />
                  <span>{marketingData?.Servicio3?.titulo}</span>
                </div>
              </Tilt>
              <Tilt
                options={{ max: 30, scale: 1 }}
                style={{ zIndex: 1, padding: 0 }}
              >
                <div className="rojo">
                  <img
                    src={analisisYEstrategia}
                    alt="Logo de Análisis y Estrategia"
                    className="image-primer-grid"
                  />
                  <span>{marketingData?.Servicio4?.titulo}</span>
                </div>
              </Tilt>
            </div>
          </div>
          <div className="segundo-grid">
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
              <div className="naranja">
                <img
                  src={socialMedia}
                  alt="Logo de Social Media"
                  className=""
                />
                <span>{marketingData?.Servicio1?.titulo}</span>
                <p>{marketingData?.Servicio1?.descripcion}</p>
                <p
                  className="listado"
                  dangerouslySetInnerHTML={{
                    __html: marketingData?.Servicio1?.listado
                  }}
                ></p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
              <div className="aqua">
                <img src={web} alt="Logo de Web" className="" />
                <span>{marketingData?.Servicio2?.titulo}</span>
                <p>{marketingData?.Servicio2?.descripcion}</p>
                <p
                  className="listado"
                  dangerouslySetInnerHTML={{
                    __html: marketingData?.Servicio2?.listado
                  }}
                ></p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
              <div className="verde">
                <img
                  src={produccionCreativa}
                  alt="Logo de Producción Creativa"
                  className=""
                />
                <span>{marketingData?.Servicio3?.titulo}</span>
                <p>{marketingData?.Servicio3?.descripcion}</p>
                <p
                  className="listado"
                  dangerouslySetInnerHTML={{
                    __html: marketingData?.Servicio3?.listado
                  }}
                ></p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              duration={1.5}
              animateOnce={true}
            >
              <div className="rojo">
                <img
                  src={analisisYEstrategia}
                  alt="Logo de Análisis y Estrategia"
                  className=""
                />
                <span>{marketingData?.Servicio4?.titulo}</span>
                <p>{marketingData?.Servicio4?.descripcion}</p>
                <p
                  className="listado"
                  dangerouslySetInnerHTML={{
                    __html: marketingData?.Servicio4?.listado
                  }}
                ></p>
              </div>
            </ScrollAnimation>
          </div>
        </main>
        <Historia ref={historiaRef}>
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1.5}
            animateOnce={true}
          >
            <TextoHistoria id="texto-historia-marketing">
              ¿Quieres saber más sobre como esto puede ayudar a tu marca a
              vender más?
            </TextoHistoria>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__fadeIn"
            duration={1}
            animateOnce={true}
          >
            <ScrollAnimation
              animateIn="animate__headShake"
              duration={2.0}
              animateOnce={true}
              className="scroll"
            >
              <Link to="/contacto">
                <LinkButton>
                  <Arrow color={colors.naranja} />
                  <ButtonText>HAGAMOS HISTORIA JUNTOS</ButtonText>
                </LinkButton>
              </Link>
            </ScrollAnimation>
          </ScrollAnimation>
          <FlexWrap id="abstracto-marketing">
            <Abstracto3D show={logoShow} />
          </FlexWrap>
          <FlexWrap id="senor-marketing">
            <img
              className="senor"
              src={returnImg('Señor')}
              alt="Señor caminando"
            />
          </FlexWrap>
        </Historia>
      </Container>
    </>
  );
}

export default MarketingDigital;
