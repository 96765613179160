import React from 'react';

const SmallA = props => {
    return (
        <svg
            id="small-a"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 23 20"
            className="small-a hidden"
        >
            <title></title>

            <g className="link" fill={props.color}>
                <path d="M16.3449 16.747C16.3449 15.4948 17.1522 14.4168 18.3049 13.93V8.57179C18.3049 5.87301 18.3049 0 9.77669 0C6.61482 0 3.49565 0.912731 1.28984 3.056L3.91093 5.5552C5.24218 4.36535 7.1556 3.57084 9.23591 3.57084C11.8984 3.57084 13.8118 4.80137 13.8118 7.14295V7.65906H12.5634C7.57089 7.65906 0 8.09509 0 14.2859C0 18.0563 3.57715 20 7.2798 20C9.94229 20 12.2723 19.0479 13.6863 16.9046H13.8105V19.5233H18.3036V19.4852C17.1781 19.0161 16.3449 17.9864 16.3449 16.747ZM13.5194 12.0638C13.5194 14.7232 11.8971 16.4292 8.48555 16.4292C6.86322 16.4292 4.9912 15.6753 4.9912 13.9287C4.9912 11.1905 9.734 10.9922 12.4793 10.9922H13.5194V12.0638Z" />
                <path d="M19.6725 13.6514C19.1847 13.6514 18.7229 13.7531 18.3037 13.9298V19.4875C18.7281 19.6655 19.1938 19.7634 19.6725 19.7634C21.5031 19.7634 22.9999 18.3739 22.9999 16.668C22.9999 14.962 21.4617 13.6514 19.6725 13.6514Z" />
            </g>
        </svg>
    );
}

export default SmallA;