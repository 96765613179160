import styled from 'styled-components';
import nosotrosHero1440 from '../../assets/images/nosotros/Nosotros_Hero1440.jpg';

import { colors, device } from '../../utils/exports';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Main = styled.section`
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  mix-blend-mode: multiply;
  background-image: url(${nosotrosHero1440});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 173px;
  padding-bottom: 100px;
  @media ${device.tablet} {
    padding-top: 211px;
  }
  @media ${device.laptop} {
    padding-top: 311px;
  }
  @media ${device.desktop} {
    padding-top: 311px;
  }
`;

const Titulo = styled.h1`
  width: auto;
  margin: 0 16px 24px;
  text-align: right;
  color: ${colors.blanco};
  @media ${device.tablet} {
    margin: 0 129px 24px;
  }
  @media ${device.laptop} {
    margin: 0 222px 24px;
  }
  @media ${device.desktop} {
    margin: 0 auto 24px;
    width: 924px;
  }
`;

const Texto = styled.p`
  font-family: 'Avenir LT Std';
  font-size: 20px;
  width: auto;
  margin: 0 16px;
  color: ${colors.blanco};
  white-space: pre-wrap;
`;

const TextoPrincipal = styled(Texto)`
  text-align: right;
  margin-bottom: 200px;
  @media ${device.tablet} {
    margin: 0 129px 100px 194px;
  }
  @media ${device.laptop} {
    margin: 0 222px 200px 465px;
    padding-left: 20px;
  }
  @media ${device.desktop} {
    margin: 0 auto 220px;
    width: 608px;
    padding-left: 316px;
  }
`;

const TextoRight = styled(Texto)`
  text-align: right;
  margin-bottom: 60px;
  @media ${device.tablet} {
    margin: 0;
  }
  @media ${device.laptop} {
    margin: 0;
  }
  @media ${device.desktop} {
    margin: 0;
  }
`;

const TextoLeft = styled(Texto)`
  text-align: left;
  margin-bottom: 60px;
  @media ${device.tablet} {
    margin: 0;
    margin-right: 5px;
    max-width: 450px;
  }
  @media ${device.laptop} {
    margin: 0;
    margin-right: 5px;
    max-width: 360px;
  }
  @media ${device.desktop} {
    margin: 0;
    margin-right: 5px;
    max-width: 470px;
  }
`;

const PrimerSet = styled.div`
  width: 100%;
  @media ${device.tablet} {
    width: auto;
    display: grid;
    grid-template-columns: 1fr 283px;
    column-gap: 24px;
    margin: 0 0 100px 129px;
  }
  @media ${device.laptop} {
    width: auto;
    display: grid;
    grid-template-columns: 1fr 347px;
    column-gap: 122px;
    margin: 0 95px 200px 222px;
  }
  @media ${device.desktop} {
    width: 974px;
    display: grid;
    grid-template-columns: 1fr 363px;
    column-gap: 137px;
    margin: 0 auto 200px auto;
    padding-left: 50px;
  }
`;

const ContainerWrap = styled.div`
  overflow: hidden;
  @media ${device.laptop} {
    width: 100%;
  }
  @media ${device.desktop} {
    width: 100%;
  }
`;

const LineContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    align-items: center;
  }
  .imagen {
    width: calc(100vw - 32px);
    margin: 0 16px;
    filter: drop-shadow(0px 8.18694px 16.3739px rgba(0, 0, 0, 0.15));
    box-shadow: none;
    border-radius: 0;
    padding-bottom: 120px;
    z-index: 1;
    @media ${device.tablet} {
      width: 283px;
      margin: 0;
      margin-top: 75px;
      padding: 0;
    }
    @media ${device.laptop} {
      width: 347px;
      margin: 0;
      margin-top: 106px;
      padding: 0;
    }
    @media ${device.desktop} {
      width: 363px;
      margin: 0;
      padding: 0;
    }
  }
  .linea {
    box-shadow: none;
    border-radius: 0;
    position: absolute;
    bottom: 59px;
    left: 63%;
    @media ${device.tablet} {
      top: 287px;
      width: 272px;
      left: 18%;
      z-index: 2;
    }
    @media ${device.laptop} {
      top: 290px;
      width: 450px;
      left: 15%;
      z-index: 2;
    }
    @media ${device.desktop} {
      top: 205px;
      width: 450px;
      left: 50%;
      z-index: 2;
    }
  }
`;

const SegundoSet = styled.div`
  width: 100%;
  margin-bottom: 60px;
  img {
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));
    box-shadow: none;
    border-radius: 0;
    width: 100%;
  }
  @media ${device.tablet} {
    width: auto;
    display: grid;
    grid-template-columns: 325px 1fr;
    column-gap: 24px;
    margin: 0 129px 100px 0;
    grid-auto-flow: dense;
    align-items: center;
    .imagen {
      grid-column: 1;
    }
    .texto {
      grid-column: 2;
    }
  }
  @media ${device.laptop} {
    width: auto;
    display: grid;
    grid-template-columns: 466px 1fr;
    column-gap: 98px;
    margin: 0 222px 37px 0;
    grid-auto-flow: dense;
    align-items: center;
    .imagen {
      grid-column: 1;
    }
    .texto {
      grid-column: 2;
    }
  }
  @media ${device.desktop} {
    width: 1156px;
    display: grid;
    grid-template-columns: 574px 1fr;
    column-gap: 137px;
    margin: 0 auto 32px auto;
    grid-auto-flow: dense;
    align-items: center;
    justify-content: center;
    padding-right: 255px;
    .imagen {
      grid-column: 1;
    }
    .texto {
      grid-column: 2;
      width: 474px;
    }
  }
`;

const Firma = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    flex-direction: row-reverse;
    width: 510px;
    margin: 0 auto;
    justify-content: space-between;
  }
  @media ${device.laptop} {
    flex-direction: row-reverse;
    width: auto;
    margin: 0 222px 0 150px;
    justify-content: space-between;
    align-items: flex-end;
  }
  @media ${device.desktop} {
    flex-direction: row-reverse;
    width: 922px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-end;
  }
  .elipses {
    width: 202px;
    margin-bottom: 60px;
    box-shadow: none;
    border-radius: 0;
    @media ${device.tablet} {
      margin: 0;
    }
    @media ${device.laptop} {
      margin-bottom: 62px;
    }
    @media ${device.desktop} {
      margin-bottom: 67px;
    }
  }
  .director {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
      border-radius: 40px;
      margin-right: 11px;
    }
    p {
      color: ${colors.blanco};
      margin: 0;
      line-height: 20px;
      font-size: 16px;
      &:first-child {
        margin-bottom: 5px;
      }
      &.puesto {
        font-size: 12px;
      }
    }
  }
`;

const Historia = styled.section`
  width: 100%;
  background: ${colors.background};
  padding-top: 100px;
  .logo,
  .senor {
    margin: 0 auto;
    box-shadow: none;
    border-radius: 0;
  }
  .logo {
    width: 288px;
    @media ${device.tablet} {
      width: 558px;
    }
    @media ${device.laptop} {
      width: 608px;
    }
    @media ${device.desktop} {
      width: 608px;
    }
  }
  .scroll {
    width: fit-content;
    margin: 0 auto;
  }
`;

const TextoHistoria = styled.h1`
  line-height: 40px;
  width: auto;
  margin: 0 16px 40px;
  text-align: center;
  white-space: pre-wrap;
  @media ${device.tablet} {
    margin: 0 105px 60px;
  }
  @media ${device.laptop} {
    margin: 0 198px 60px;
  }
  @media ${device.desktop} {
    margin: 0 262px 60px;
  }
`;

const TextoCallToAction = styled.p`
  font-family: Avenir LT Std;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 44px;
  @media ${device.tablet} {
    margin-bottom: 64px;
  }
  @media ${device.laptop} {
    margin-bottom: 64px;
  }
  @media ${device.desktop} {
    margin-bottom: 64px;
  }
`;

const Listo = styled.h2`
  width: auto;
  margin: 0 16px 64px;
  text-align: center;
`;

const LinkButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 288px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const ButtonText = styled.p`
  font-size: 18px;
  color: ${colors.naranja};
  margin: 0;
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
`;

//Sidebars
const FixedSide = styled.section`
  width: 198px;
  height: 100vh;
  position: fixed;
  top: 0;
  display: none;
  z-index: 10;
  pointer-events: none;
  cursor: none;
  @media ${device.laptop} {
    display: block;
  }
  @media ${device.desktop} {
    display: block;
    width: 258px;
  }
`;
const FixedLeft = styled(FixedSide)`
  left: 0;
  @media ${device.tablet} {
    display: block;
    width: 129px;
  }
`;
const FixedRight = styled(FixedSide)`
  right: 0;
`;

const LeftSideFlexWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 22px;
  margin-left: 100px;
  margin-right: 76px;
  height: fit-content;
  bottom: 55px;
  position: fixed;
  img {
    box-shadow: none;
    border-radius: 0;
    &.one-day {
      margin-bottom: 10px;
    }
    &.icono {
      margin-bottom: 105px;
    }
  }
  .fb {
    margin-bottom: 36px;
    cursor: pointer;
  }
  .ig {
    cursor: pointer;
  }
  @media ${device.desktop} {
    margin-right: 136px;
  }
  @media ${device.tablet} {
    margin-left: 40px;
    bottom: 180px;
    transition: 0.25s linear;
  }
`;
const RightSideFlexWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 42px;
  margin-right: 100px;
  margin-left: 55px;
  height: fit-content;
  position: fixed;
  bottom: 340px;
  img {
    box-shadow: none;
    border-radius: 0;
  }
  @media ${device.desktop} {
    margin-left: 136px;
  }
`;

const Section = styled.p`
  font-size: 16px;
  line-height: 19px;
  font-family: 'Avenir LT Std';
  transform: rotate(-90deg);
  color: ${props => props.color};
  transition: 0.5s linear;
`;

const SectionLeft = styled(Section)`
  width: 159px;
  height: 159px;
`;

export {
  Container,
  Main,
  Titulo,
  TextoPrincipal,
  TextoRight,
  TextoLeft,
  PrimerSet,
  LineContainer,
  SegundoSet,
  Firma,
  Historia,
  TextoHistoria,
  Listo,
  LinkButton,
  ButtonText,
  FlexWrap,
  ContainerWrap,
  FixedLeft,
  FixedRight,
  LeftSideFlexWrap,
  RightSideFlexWrap,
  Section,
  SectionLeft,
  TextoCallToAction
};
