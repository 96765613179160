const { useEffect, useCallback, useState } = require('react');

export const useResize = () => {
  const [size, setSize] = useState();
  const handleResize = useCallback(() => {
    const windowWidth = window.innerWidth;
    // Mobile
    if (windowWidth < 767) {
      setSize('MOBILE');
    } // Tablet
    else if (windowWidth >= 767 && windowWidth < 1151) {
      setSize('TABLET');
    }
    // Laptop
    else if (windowWidth >= 1151 && windowWidth < 1439) {
      setSize('LAPTOP');
    }
    //Laptop-desktop
    else if (windowWidth >= 1439) {
      setSize('DESKTOP');
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return {
    isMobile: size === 'MOBILE',
    isTablet: size === 'TABLET',
    isLaptop: size === 'LAPTOP',
    isDesktop: size === 'DESKTOP'
  };
};
