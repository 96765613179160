import React, { useEffect } from 'react';

import './ErrorPage.scss';

import errorMobile from '../../assets/images/404.svg';

function ErrorPage({ setHeaderLogoColor }) {
  useEffect(() => setHeaderLogoColor('black'), [setHeaderLogoColor]);
  return (
    <>
      <div className="error-page">
        <img
          className="error-page__image"
          src={errorMobile}
          alt="página de error"
        ></img>
        <h1 className="error-page__header subtitle1 black">
          ¡Esta página no existe!
        </h1>
        <p className="error-page__paragraph black">
          Has click
          <a href="/" className="error-page__enlace headline3">
            aquí
          </a>
          para regresar a la página principal
        </p>
      </div>
    </>
  );
}

export default ErrorPage;
