import React from "react";
import { motion } from "framer-motion";

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0,0%,18%)"
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({ toggle, color }) => (
  <button onClick={toggle} className="toggle-vert">
    <svg width="23" height="23" viewBox=" 0 0 23 23">
      <Path
        d="M 2 2.5 L 20 2.5"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 }
        }}
        stroke={color}
      />
      <Path
        d="M 3 16.5 L 17 2.5"
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 }
        }}
        stroke="black"
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 }
        }}
        transition={{ duration: 0.1 }}
        stroke={color}
      />
      <Path
        d="M 2 16.346 L 20 16.346"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 }
        }}
        stroke={color}
      />
      <Path
        d="M 3 2.5 L 17 16.346"
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 }
        }}
        stroke="black"
      />
    </svg>
  </button>
);
