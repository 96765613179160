import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../../utils/exports';
import Menu from './MenuVertical/Menu';
import MenuH from './MenuHorizontal/MenuH';
import Logo from '../../../assets/svgs/Logo';
import SmallLogo from '../../../assets/svgs/SmallLogo';
import styled from 'styled-components';
import { device } from '../../../utils/exports';

const Transition = styled.div`
  display: flex;
  justify-content: center;
  pointer-events: none;
  .active {
    visibility: visible;
    transition: all 400ms ease-in;
  }
  .hidden {
    visibility: hidden;
    transition: all 400ms ease-out;
    transform: translate(0, -100%);
  }
  #headerBar {
    width: 100%;
    position: fixed;
    top: 0;
    height: 191px;
    z-index: 1999;
    pointer-events: none;
    .active {
      visibility: visible;
      transition: all 400ms ease-in;
    }
    .hidden {
      visibility: hidden;
      transition: all 400ms ease-out;
      transform: translate(0, -200%);
    }
    #headerBar {
      width: 100%;
      position: fixed;
      top: 0;
      height: 191px;
      z-index: 1999;
      pointer-events: none;
    }
    .scrollUp {
    }
  }
`;

const HeaderBar = styled.div`
  width: fill-available;
  margin: 28px 16px;
  height: 76px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 2000;
  background-color: ${colors.blanco};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  @media ${device.tablet} {
    margin: 57px 30px;
  }
  @media ${device.laptop} {
    margin: 57px 77px;
  }
  @media ${device.desktop} {
    margin: 57px 77px;
  }
  a {
    pointer-events: auto;
  }
  .logo {
    width: 154px;
    height: 31px;
    @media ${device.tablet} {
      margin-top: 23px;
      margin-left: 23px;
    }
    @media ${device.laptop} {
      margin-top: 23px;
      margin-left: 23px;
    }
    @media ${device.desktop} {
      margin-top: 23px;
      margin-left: 23px;
    }
  }
  .small-logo {
    margin-top: 28px;
    margin-left: 10px;
  }
`;

const MenuButton = styled.div`
  width: 104px;
  height: 66px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  @media ${device.tablet} {
    margin-top: 67px;
  }
`;

const CotiHeader = ({ color }) => {
  const [show, setShow] = useState('true');
  const [scrollPos, setScrollPos] = useState(0);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [menuVersion, setMenuVersion] = useState(
    window.innerWidth > 1151 ? 'desktop' : 'movil'
  );
  const [headerColor, setHeaderColor] = useState(colors.blanco);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleScroll = () => {
    setScrollPos(document.body.getBoundingClientRect().top);
    if (document.body.getBoundingClientRect().top >= 0) {
      setShow(true);
    } else {
      if (menuVersion === 'desktop') {
        setShow(document.body.getBoundingClientRect().top > scrollPos);
      }
      if (menuVersion === 'movil') {
        setShow(document.body.getBoundingClientRect().top > scrollPos);
      }
    }
    if (document.getElementById('small-a')) {
      if (document.body.getBoundingClientRect().top < 0) {
        if (document.body.getBoundingClientRect().top > scrollPos) {
          document.getElementById('small-a').classList.remove('active');
          document.getElementById('small-a').classList.add('hidden');
        } else {
          document.getElementById('small-a').classList.remove('hidden');
          document.getElementById('small-a').classList.add('active');
        }
      }
    }
  };

  const handleResize = () => {
    if (window.innerWidth > 1151 && windowSize < 1151) {
      setMenuVersion('desktop');
    }
    if (window.innerWidth < 1151 && windowSize > 1151) {
      setMenuVersion('movil');
      setHeaderColor(colors.blanco);
      setShow(true);
    }
    setWindowSize(window.innerWidth);
  };

  return (
    <Transition>
      <HeaderBar id="cotiHeader" className={show ? 'active' : 'hidden'}>
        <Link to="/">
          {window.innerWidth < 768 ? (
            <SmallLogo color={color} />
          ) : (
            <Logo color={color} />
          )}
        </Link>
        {menuVersion === 'movil' ? (
          <MenuButton>
            <Menu color={menuVersion === 'movil' ? color : headerColor} />
          </MenuButton>
        ) : (
          <MenuButton>
            <MenuH color={menuVersion === 'desktop' ? color : headerColor} />
          </MenuButton>
        )}
      </HeaderBar>
      <div id="headerBar" className={show ? 'active' : 'hidden'} />
    </Transition>
  );
};

export default CotiHeader;
