import React, { useState, createContext, useContext } from 'react';

const HeaderContext = createContext();

export const useHeaderContext = () => useContext(HeaderContext);

export const HeaderContextProvider = ({ children }) => {
  const [aColor, setAColor] = useState();
  return (
    <HeaderContext.Provider value={{aColor, setAColor}}>
      {children}
    </HeaderContext.Provider>
  );
};
